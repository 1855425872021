/* eslint-disable react-hooks/exhaustive-deps */
// Packages
import React, { useContext, useEffect, useState } from 'react'
import { Routes, Route, useNavigate } from 'react-router-dom'
import { Amplify } from 'aws-amplify'
import { getCurrentUser } from 'aws-amplify/auth'
// Helpers
import formNameMap from '../helpers/supportData/formNameMap'
// Contexts
import { ConfigContext } from '../contexts/Config'
import { UserContext } from '../contexts/User'

import amplifyConfigDev from '../amplifyconfiguration-dev.json'
import amplifyConfigProd from '../amplifyconfiguration.json'

function GenerateRoutes() {
    // Contexts
    const [config]: any = useContext(ConfigContext)
    const [user, userDispatch]: any = useContext(UserContext)
    const [, configDispatch]: any = useContext(ConfigContext)
    // States
    const [paths, setPaths] = useState([])
    const navigate = useNavigate()

    useEffect(() => {
        if (config?.paths?.paths?.length > 0) {
            setPaths(config.paths.paths)
        }
        if (config.account === 'dev') {
            Amplify.configure(amplifyConfigDev)
        } else {
            Amplify.configure(amplifyConfigProd)
        }
    }, [config.paths])

    async function currentAuthenticatedUser() {
        try {
            const { username, userId, signInDetails } = await getCurrentUser()
            userDispatch({
                type: 'SET',
                payload: { username, userId, signInDetails },
            })
        } catch (err) {
            // Navigate user back to the login page!
            configDispatch({
                type: 'CLEAR_LOADED',
            })
            if (config.account === 'dev') {
                navigate('/login/?isMock&devMode')
            } else {
                navigate('/login')
            }
        }
    }

    useEffect(() => {
        for (let i = 0; i < config?.paths?.paths?.length; i += 1) {
            const currentPathObj = config?.paths?.paths[i]
            if (user?.currentPage === currentPathObj?.form?.component && currentPathObj?.form?.isPrivate) {
                currentAuthenticatedUser()
            }
        }
    }, [user?.currentPage])

    return paths.map((pathObject: { path: string; form: { component: string; config: string } }) => {
        return (
            <Routes key={`${pathObject.path}-${pathObject.form}`}>
                {/* TODO: Implement a private routes component */}
                <Route
                    path={pathObject.path}
                    element={formNameMap({
                        key: pathObject.form.component,
                        config: pathObject.form.config,
                    })}
                />
            </Routes>
        )
    })
}

export default GenerateRoutes
