// Packages
import React, { ReactNode, useEffect, useState } from 'react'
import reactStringReplace from 'react-string-replace'
// UI
import Link from '../ui/Link'

function StyleCopy({ children }: { children: string | ReactNode[] | undefined }) {
    const [bodyText, setBodyText]: any = useState(undefined)
    const styleIdentifiers = [
        {
            pattern: /<link>(.*?)<\/link>/gm,
            functionToRunOnMatched: (match: string) => {
                const linkAndLinkText = match.split('|')

                const link =
                    linkAndLinkText[1].startsWith('https://') ||
                    linkAndLinkText[1].startsWith('tel:') ||
                    linkAndLinkText[1].startsWith('popup:')
                        ? linkAndLinkText[1]
                        : `https://${linkAndLinkText[1]}`
                const linkText = linkAndLinkText[0]

                return (
                    <Link
                        className="text-blue-600 visited:text-purple-600"
                        key={Math.random() * (1000 - 0) + 0}
                        href={link}
                    >
                        {linkText}
                    </Link>
                )
            },
        },
    ]

    useEffect(() => {
        let tempBodyText = children

        for (let i = 0; i < styleIdentifiers.length; i += 1) {
            tempBodyText = reactStringReplace(
                tempBodyText,
                styleIdentifiers[i].pattern,
                styleIdentifiers[i].functionToRunOnMatched
            )
        }

        setBodyText(tempBodyText)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [children])

    return bodyText
}

export default StyleCopy
