import { capitalizeFirstLetter } from './string'

export const getProviderDisplayName = (tenant: string) => {
    switch (tenant) {
        case 'freeconnect':
            return 'FreeConnect'
        case 'redpocket':
            return 'Red Pocket Mobile'
        case 'gotech':
            return 'Go Technology'
        case 'frontier':
            return 'Frontier Communications'
        default:
            return capitalizeFirstLetter(tenant)
    }
}

export const getProviderSubsidiaries = (tenant: string) => {
    switch (tenant) {
        case 'optimum':
            return 'Optimum, Altice, or Suddenlink'
        default:
            return getProviderDisplayName(tenant)
    }
}
