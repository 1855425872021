/* eslint-disable react/prop-types */
// Packages
import React, { useContext } from 'react'
// Contexts
import { ConfigContext } from '../contexts/Config'
// Helpers
import { capitalizeFirstLetter } from '../helpers/string'

function ExtraSmallText({ className = '', children, name }: { [key: string]: any }) {
    // Contexts
    const [config]: any = useContext(ConfigContext)

    if (config?.tenantConfig?.featureFlags?.[`disable${capitalizeFirstLetter(name)}ExtraSmallText`]) {
        return null
    }
    return <p className={`${className}`}>{children}</p>
}

export default ExtraSmallText
