const isLeapYear = (year: string): boolean => {
    const yearInt = parseInt(year, 10)

    if ((yearInt % 4 === 0 && yearInt % 100 !== 0) || yearInt % 400 === 0) {
        return true
    }

    return false
}

export default isLeapYear
