/* eslint-disable react-hooks/exhaustive-deps */
// Packages
import React, { useContext, useEffect, useState } from 'react'
// Components
import StyleCopy from '../../components/StyleCopy'
// UI
import Page from '../../ui/Page'
import Main from '../../ui/Main'
import Container from '../../ui/Container'
import HeaderText from '../../ui/HeaderText'
import BodyText from '../../ui/BodyText'
import Button from '../../ui/Button'
// Contexts
import { FormDataContext } from '../../contexts/FormData'
import { CopyContext } from '../../contexts/Copy'
import { ConfigContext } from '../../contexts/Config'
// Helpers
import { parseCopy } from '../../helpers/parseCopy'

function AcpTransferQuestion({ onNext }: any) {
    // States
    const [fieldSet, setFieldSet] = useState(false)
    // Contexts
    const [formData, formDataDispatch]: any = useContext(FormDataContext)
    const [copy]: any = useContext(CopyContext)
    const [config]: any = useContext(ConfigContext)

    useEffect(() => {
        if (fieldSet) {
            onNext()
        }
    }, [fieldSet])

    return (
        <Page>
            <Main>
                <Container>
                    <HeaderText name="acpTransferQuestionHeader">
                        <StyleCopy>
                            {parseCopy(copy?.steps?.acpTransferQuestion?.title, {
                                config,
                                formData,
                                copy: copy?.steps?.acpTransferQuestion,
                            })}
                        </StyleCopy>
                    </HeaderText>
                    <BodyText name="acpTransferQuestionSubHeader">
                        <StyleCopy>
                            {parseCopy(copy?.steps?.acpTransferQuestion?.subHeader, {
                                config,
                                formData,
                                copy: copy?.steps?.acpTransferQuestion,
                            })}
                        </StyleCopy>
                    </BodyText>
                    <Button
                        name="acpTransferQuestionNoButton"
                        onClick={() => {
                            formDataDispatch({
                                type: 'SET_FIELD',
                                payload: { name: 'isAcpTransferable', value: false },
                            })
                            setFieldSet(true)
                        }}
                    >
                        <StyleCopy>
                            {parseCopy(copy?.steps?.acpTransferQuestion?.noButton, {
                                config,
                                formData,
                                copy: copy?.steps?.acpTransferQuestion,
                            })}
                        </StyleCopy>
                    </Button>
                    <Button
                        name="acpTransferQuestionYesButton"
                        onClick={() => {
                            formDataDispatch({
                                type: 'SET_FIELD',
                                payload: { name: 'isAcpTransferable', value: true },
                            })
                            setFieldSet(true)
                        }}
                    >
                        <StyleCopy>
                            {parseCopy(copy?.steps?.acpTransferQuestion?.yesButton, {
                                config,
                                formData,
                                copy: copy?.steps?.acpTransferQuestion,
                            })}
                        </StyleCopy>
                    </Button>
                </Container>
            </Main>
        </Page>
    )
}

export default AcpTransferQuestion
