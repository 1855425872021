// Packages
import React, { useContext, useEffect, useRef } from 'react'
// UI
import Loading from '../../ui/LoadingBlocker'
// Contexts
import { FormDataContext } from '../../contexts/FormData'

function BasicServiceabilityCheckBlocker({ onNext }: any) {
    // Contexts
    const [formData]: any = useContext(FormDataContext)
    // Refs
    const useEffectBlocker = useRef(false)

    useEffect(() => {
        if (useEffectBlocker.current) return

        if (formData?.topServiceableCompany) {
            onNext()
            useEffectBlocker.current = true
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData?.topServiceableCompany])

    return <Loading />
}

export default BasicServiceabilityCheckBlocker
