// Step Action Functions
import basicAddress from './basicAddress'
import interimFormP2Branching from './interimFormP2Branching'
import interimP2ExistingCustomerQuestion from './interimP2ExistingCustomerQuestion'
import defaultAction from './default'

const stepActions: { [key: string]: Function } = {
    basicAddress,
    interimFormP2Branching,
    interimP2ExistingCustomerQuestion,
    defaultAction,
}

const getStepActions = ({ key }: { key: string }) => {
    return stepActions?.[key] ? stepActions[key] : defaultAction
}

export default getStepActions
