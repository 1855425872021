// Helpers
import initialState from './initialState'

const DataFunnelReducer = (state: any, action: { type: String; payload: any }) => {
    switch (action.type) {
        case 'SET':
            return action.payload
        case 'SET_DEFAULT':
            return initialState
        case 'INIT_DATA_OBJECT':
            return {
                ...state,
                contexts: {
                    ...state.contexts,
                    [action.payload.name]: { ...state[action.payload.name], ...action.payload.object },
                },
            }
        case 'SET_QUERY_PARAMS':
            return { ...state, contexts: { ...state.contexts, queryParams: action.payload } }
        case 'SET_COPY':
            return { ...state, contexts: { ...state.contexts, copy: action.payload } }
        case 'SET_FORM_DATA':
            return { ...state, contexts: { ...state.contexts, formData: action.payload } }
        case 'SET_CONFIG':
            return { ...state, contexts: { ...state.contexts, config: action.payload } }
        case 'SET_USER':
            return { ...state, contexts: { ...state.contexts, user: action.payload } }
        case 'SET_INITIAL_LOAD_BLOCKERS':
            return { ...state, contexts: { ...state.contexts, initialLoadBlockers: action.payload } }
        case 'SET_LOADING_BLOCKERS':
            return { ...state, contexts: { ...state.contexts, loadingBlockers: action.payload } }
        case 'SET_VALIDATION':
            return { ...state, contexts: { ...state.contexts, validation: action.payload } }
        case 'SET_STEPS':
            return { ...state, contexts: { ...state.contexts, steps: { ...state.contexts.steps, ...action.payload } } }
        case 'SET_STYLES':
            return { ...state, contexts: { ...state.contexts, styles: action.payload } }
        case 'SET_POPUP':
            return { ...state, contexts: { ...state.contexts, popup: action.payload } }
        case 'SET_INSTANCE':
            return { ...state, contexts: { ...state.contexts, instance: action.payload } }
        case 'SET_STEPS_FIELD':
            return {
                ...state,
                contexts: {
                    ...state.contexts,
                    steps: { ...state.contexts.steps, [action.payload.key]: action.payload.value },
                },
            }
        case 'SET_CONFIG_FIELD':
            return {
                ...state,
                contexts: {
                    ...state.contexts,
                    config: { ...state.contexts.config, [action.payload.key]: action.payload.value },
                },
            }
        case 'SET_FORM_DATA_FIELD':
            return {
                ...state,
                contexts: {
                    ...state.contexts,
                    formData: { ...state.contexts.formData, [action.payload.key]: action.payload.value },
                },
            }
        case 'SET_INITIAL_LOAD_BLOCKERS_FIELD':
            return {
                ...state,
                contexts: {
                    ...state.contexts,
                    initialLoadBlockers: {
                        ...state.contexts.initialLoadBlockers,
                        [action.payload.key]: action.payload.value,
                    },
                },
            }
        case 'SET_INPUT_LOADING_BLOCKERS_FIELD':
            return {
                ...state,
                contexts: {
                    ...state.contexts,
                    loadingBlockers: {
                        ...state.contexts.loadingBlockers,
                        blockers: {
                            ...state.contexts.loadingBlockers.blockers,
                            [action.payload.key]: action.payload.value,
                        },
                    },
                },
            }
        case 'SET_PAGE_LOADING_BLOCKERS_FIELD':
            return {
                ...state,
                contexts: {
                    ...state.contexts,
                    loadingBlockers: {
                        ...state.contexts.loadingBlockers,
                        pageBlockers: {
                            ...state.contexts.loadingBlockers.pageBlockers,
                            [action.payload.key]: action.payload.value,
                        },
                    },
                },
            }
        case 'SET_VALIDATION_FIELD':
            return {
                ...state,
                contexts: {
                    ...state.contexts,
                    validation: { ...state.contexts.validation, [action.payload.key]: action.payload.value },
                },
            }
        case 'SET_POPUP_FIELD':
            return {
                ...state,
                contexts: {
                    ...state.contexts,
                    popup: { ...state.contexts.popup, [action.payload.key]: action.payload.value },
                },
            }
        case 'START_TIMER':
            return { ...state, timers: { ...state.timers, [action.payload.name]: { start: Date.now() } } }
        case 'END_TIMER': {
            const endTime = Date.now()

            return {
                ...state,
                timers: {
                    ...state.timers,
                    [action.payload.name]: {
                        ...state.timers[action.payload.name],
                        end: endTime,
                        count: endTime - state.timers[action.payload.name].start,
                    },
                },
            }
        }
        case 'START_MERGED_TIMER': {
            const startTime = Date.now()

            if (state?.timers?.[action?.payload?.name]?.start) {
                const latestTime =
                    state.timers[action.payload.name].start[state.timers[action.payload.name].start.length - 1]
                if (!(latestTime - 20 <= startTime && latestTime + 20 >= startTime)) {
                    return {
                        ...state,
                        timers: {
                            ...state.timers,
                            [action.payload.name]: {
                                ...state.timers[action.payload.name],
                                start: [
                                    ...(state?.timers?.[action?.payload?.name]?.start
                                        ? state.timers[action.payload.name].start
                                        : []),
                                    startTime,
                                ],
                            },
                        },
                    }
                }
            } else {
                return {
                    ...state,
                    timers: {
                        ...state.timers,
                        [action.payload.name]: {
                            ...state.timers[action.payload.name],
                            start: [
                                ...(state?.timers?.[action?.payload?.name]?.start
                                    ? state.timers[action.payload.name].start
                                    : []),
                                startTime,
                            ],
                        },
                    },
                }
            }

            return state
        }
        case 'END_MERGED_TIMER': {
            const endTime = Date.now()

            if (state?.timers?.[action?.payload?.name]?.end) {
                const latestTime =
                    state.timers[action.payload.name].end[state.timers[action.payload.name].end.length - 1]
                if (!(latestTime - 20 <= endTime && latestTime + 20 >= endTime)) {
                    return {
                        ...state,
                        timers: {
                            ...state.timers,
                            [action.payload.name]: {
                                ...state.timers[action.payload.name],
                                end: [
                                    ...(state?.timers?.[action?.payload?.name]?.end
                                        ? state.timers[action.payload.name].end
                                        : []),
                                    endTime,
                                ],
                                count:
                                    (state?.[action?.payload?.name]?.count ? state[action.payload.name].count : 0) +
                                    (endTime -
                                        state.timers[action.payload.name].start[
                                            state.timers[action.payload.name].start.length - 1
                                        ]),
                            },
                        },
                    }
                }
            } else {
                return {
                    ...state,
                    timers: {
                        ...state.timers,
                        [action.payload.name]: {
                            ...state.timers[action.payload.name],
                            end: [
                                ...(state?.timers?.[action?.payload?.name]?.end
                                    ? state.timers[action.payload.name].end
                                    : []),
                                endTime,
                            ],
                            count:
                                (state?.[action?.payload?.name]?.count ? state[action.payload.name].count : 0) +
                                (endTime -
                                    state.timers[action.payload.name].start[
                                        state.timers[action.payload.name].start.length - 1
                                    ]),
                        },
                    },
                }
            }

            return state
        }
        default:
            return state
    }
}

export default DataFunnelReducer
