/* eslint-disable react-hooks/exhaustive-deps */
// Packages
import React, { useEffect, useContext, useState } from 'react'
// Contexts
import { ValidationContext } from '../contexts/Validation'
import { CopyContext } from '../contexts/Copy'
// Helpers
import { validateSingleField } from '../helpers/validation/main'
import stringReplace from '../helpers/stringReplace'

// Interface
// {
//     autoComplete: string
//     children: React.ReactElement | string
//     className: string
//     inputClassName: string
//     disabled: boolean | undefined
//     name: string
//     onChange: Function
//     placeholder: string
//     isNumberType: boolean
// }

function Dropdown({
    step = null,
    children,
    className = '',
    name,
    onChange = () => {},
    validationFunctions = [],
    formDataKey = '',
    disableOnChangeValidation = false,
    initialValue = '',
    options = [],
    subLabel = '',
    setValueTo,
}: any) {
    // Contexts
    const [validation, validationDispatch]: any = useContext(ValidationContext)
    const [copy]: any = useContext(CopyContext)
    // States
    const [createdValidation, setCreatedValidation] = useState(false)
    const [value, setValue] = useState(initialValue)
    const [waitToValidate, setWaitToValidate] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    useEffect(() => {
        if ((step || step === 0) && name && !createdValidation) {
            validationDispatch({
                type: 'ADD_FIELD',
                payload: {
                    step,
                    name,
                    formDataKey,
                    functions: validationFunctions,
                },
            })
            setCreatedValidation(true)
        }
    }, [createdValidation, formDataKey, name, step, validationFunctions])

    useEffect(() => {
        if (setValueTo) {
            setValue(setValueTo === -1 ? '' : setValueTo)
            onChange(setValueTo === -1 ? '' : setValueTo, true)
            if (!waitToValidate) {
                setWaitToValidate(true)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setValueTo])

    useEffect(() => {
        const asyncWrapper = async () => {
            const { message, metaData } = await validateSingleField(value, validationFunctions)
            setErrorMessage(
                stringReplace(copy?.validationErrors?.[message] ? copy?.validationErrors?.[message] : '', metaData)
            )
        }

        if (waitToValidate && !disableOnChangeValidation) {
            asyncWrapper()
        }
    }, [value, validationFunctions, waitToValidate, disableOnChangeValidation, copy?.validationErrors])

    useEffect(() => {
        if (validation?.errors?.errorMessages?.[name]) {
            if (errorMessage !== validation.errors[name]) {
                setErrorMessage(
                    stringReplace(
                        copy?.validationErrors?.[validation.errors.errorMessages[name]]
                            ? copy?.validationErrors?.[validation.errors.errorMessages[name]]
                            : '',
                        validation.errors.metaDatas[name]
                    )
                )
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [validation.errors])

    return (
        // eslint-disable-next-line jsx-a11y/label-has-associated-control
        <label className={`${className}`}>
            {children}
            <div className={subLabel ? '' : 'hidden'}>{subLabel}</div>
            <select
                className="my-4 border-2 rounded-lg"
                name={name}
                value={value}
                onChange={(event) => {
                    onChange(event.target.value)
                    setValue(event.target.value)
                    if (!waitToValidate) {
                        setWaitToValidate(true)
                    }
                }}
            >
                {options.map((option: { name: string; value: any }) => (
                    <option key={option.name} value={option.value}>
                        {option.name}
                    </option>
                ))}
            </select>
            {errorMessage}
        </label>
    )
}

export default Dropdown
