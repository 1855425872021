/* eslint-disable no-unused-vars */
// Helpers
import { log } from '../logging'

export const onNext = async (params: any) => {
    log({
        config: params.config,
        title: 'P2 Existing Customer Question Step Action',
        message: 'Ran onNext',
    })
    if (!params?.formData?.existingCustomer) {
        if (params?.formData?.topServiceableCompany === 'optimum') {
            params.stepsDispatch({ type: 'APPEND_STEPS', payload: { steps: params.steps.optimumSuccess } })
        } else {
            params.stepsDispatch({ type: 'APPEND_STEPS', payload: { steps: params.steps.genericSuccess } })
        }
    } else {
        params.stepsDispatch({ type: 'APPEND_STEPS', payload: { steps: params.steps.unserviceable } })
    }
}
export const onPrev = async (params: any) => {
    log({
        config: params.config,
        title: 'P2 Existing Customer Question Step Action',
        message: 'Ran onPrev',
    })
}
export const onSubmit = async (params: any) => {
    log({
        config: params.config,
        title: 'P2 Existing Customer Question Step Action',
        message: 'Ran onSubmit',
    })
    onNext(params)
    params.stepsDispatch({ type: 'INCREMENT_CURRENT_STEP' })
}

const onAction = async (action: string, params: any) => {
    if (action === 'next') {
        await onNext(params)
    } else if (action === 'prev') {
        await onPrev(params)
    } else if (action === 'submit') {
        await onSubmit(params)
    }
}

export default onAction
