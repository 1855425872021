/* eslint-disable no-unused-vars */
// Helpers
import { log } from '../logging'

export const onNext = async (params: any) => {
    log({
        config: params.config,
        title: 'Basic Address Step Action',
        message: 'Ran onNext',
    })
    params.formDataDispatch({
        type: 'SET_FIELD',
        payload: {
            name: 'fullAddress',
            value: `${params.formData.addressStreet}${params?.formData?.addressSecondary ? `, ${params.formData.addressSecondary}` : ''}${params?.formData?.addressCity ? `, ${params.formData.addressCity}` : ''}${params?.formData?.addressState ? `, ${params.formData.addressState}` : ''}${params?.formData?.addressZip ? `, ${params.formData.addressZip}` : ''}`,
        },
    })
}
export const onPrev = async (params: any) => {
    log({
        config: params.config,
        title: 'Basic Address Step Action',
        message: 'Ran onPrev',
    })
}
export const onSubmit = async (params: any) => {
    log({
        config: params.config,
        title: 'Basic Address Step Action',
        message: 'Ran onSubmit',
    })
}

const onAction = async (action: string, params: any) => {
    if (action === 'next') {
        await onNext(params)
    } else if (action === 'prev') {
        await onPrev(params)
    } else if (action === 'submit') {
        await onSubmit(params)
    }
}

export default onAction
