/* eslint-disable indent */
// Packages
import React, { useContext, useState } from 'react'
// UI
import Page from '../../ui/Page'
import Main from '../../ui/Main'
import Container from '../../ui/Container'
import HeaderText from '../../ui/HeaderText'
import BodyText from '../../ui/BodyText'
import Button from '../../ui/Button'
// Helpers
import getHardcodedPrograms from '../../helpers/programs'
// Contexts
import { FormDataContext } from '../../contexts/FormData'
import { CopyContext } from '../../contexts/Copy'

function HardcodedPrograms({ onNext }: any) {
    // Contexts
    const [, formDataDispatch]: any = useContext(FormDataContext)
    const [copy]: any = useContext(CopyContext)
    // States
    const [isShowMore, setIsShowMore] = useState(false)
    const [programs, setPrograms] = useState(
        getHardcodedPrograms(copy?.steps?.hardcodedPrograms?.programs, copy?.steps?.hardcodedPrograms?.displayTopX)
    )

    return (
        <Page>
            <Main>
                <Container>
                    <HeaderText>{copy?.steps?.hardcodedPrograms?.title}</HeaderText>
                    <BodyText>{copy?.steps?.hardcodedPrograms?.subHeader}</BodyText>
                    {programs.map((program) => {
                        return (
                            <Button
                                className="block"
                                key={program.code}
                                onClick={() => {
                                    formDataDispatch({
                                        type: 'SET_FIELD',
                                        payload: { name: 'programCode', value: program.code },
                                    })
                                    onNext()
                                }}
                            >
                                {program.code}
                            </Button>
                        )
                    })}
                    <Button
                        onClick={() => {
                            setPrograms(
                                isShowMore
                                    ? getHardcodedPrograms(
                                          copy?.steps?.hardcodedPrograms?.programs,
                                          copy?.steps?.hardcodedPrograms?.displayTopX
                                      )
                                    : getHardcodedPrograms(copy?.steps?.hardcodedPrograms?.programs)
                            )
                            setIsShowMore((prev) => !prev)
                        }}
                    >
                        {isShowMore
                            ? copy?.steps?.hardcodedPrograms?.showLess
                            : copy?.steps?.hardcodedPrograms?.showMore}
                    </Button>
                </Container>
            </Main>
        </Page>
    )
}

export default HardcodedPrograms
