// Packages
import React, { useContext } from 'react'
// Components
import StyleCopy from '../../components/StyleCopy'
// UI
import Page from '../../ui/Page'
import Main from '../../ui/Main'
import Container from '../../ui/Container'
import HeaderText from '../../ui/HeaderText'
import BodyText from '../../ui/BodyText'
import ExtraSmallText from '../../ui/ExtraSmallText'
// Contexts
import { CopyContext } from '../../contexts/Copy'
import { ConfigContext } from '../../contexts/Config'
import { FormDataContext } from '../../contexts/FormData'
// Helpers
import { parseCopy } from '../../helpers/parseCopy'

function NotAcpTransferable() {
    // Contexts
    const [copy]: any = useContext(CopyContext)
    const [config]: any = useContext(ConfigContext)
    const [formData]: any = useContext(FormDataContext)

    return (
        <Page>
            <Main>
                <Container>
                    <HeaderText name="interimFormOptimumSuccessNonAcpHeader">
                        <StyleCopy>
                            {parseCopy(copy?.steps?.interimFormOptimumSuccess?.notAcpTransferable?.title, {
                                config,
                                formData,
                                copy: copy?.steps?.interimFormOptimumSuccess?.notAcpTransferable,
                            })}
                        </StyleCopy>
                    </HeaderText>
                    <BodyText name="interimFormOptimumSuccessNonAcpSubHeader">
                        <StyleCopy>
                            {parseCopy(copy?.steps?.interimFormOptimumSuccess?.notAcpTransferable?.subHeader, {
                                config,
                                formData,
                                copy: copy?.steps?.interimFormOptimumSuccess?.notAcpTransferable,
                            })}
                        </StyleCopy>
                    </BodyText>
                    <BodyText name="interimFormOptimumSuccessNonAcpFooterTitle">
                        <StyleCopy>
                            {parseCopy(copy?.steps?.interimFormOptimumSuccess?.notAcpTransferable?.footerText?.title, {
                                config,
                                formData,
                                copy: copy?.steps?.interimFormOptimumSuccess?.notAcpTransferable,
                            })}
                        </StyleCopy>
                    </BodyText>
                    <ExtraSmallText name="interimFormOptimumSuccessNonAcpFooterOne">
                        {parseCopy(
                            copy?.steps?.interimFormOptimumSuccess?.notAcpTransferable?.footerText?.disclaimerOne,
                            {
                                config,
                                formData,
                                copy: copy?.steps?.interimFormOptimumSuccess?.notAcpTransferable,
                            }
                        )}
                    </ExtraSmallText>
                    <ExtraSmallText name="interimFormOptimumSuccessNonAcpFooterTwo">
                        <StyleCopy>
                            {parseCopy(
                                copy?.steps?.interimFormOptimumSuccess?.notAcpTransferable?.footerText?.disclaimerTwo,
                                {
                                    config,
                                    formData,
                                    copy: copy?.steps?.interimFormOptimumSuccess?.notAcpTransferable,
                                }
                            )}
                        </StyleCopy>
                    </ExtraSmallText>
                </Container>
            </Main>
        </Page>
    )
}

export default NotAcpTransferable
