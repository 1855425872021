// Packages
import React, { useContext } from 'react'
// Components
import StyleCopy from '../../components/StyleCopy'
// UI
import Page from '../../ui/Page'
import Main from '../../ui/Main'
import Container from '../../ui/Container'
import HeaderText from '../../ui/HeaderText'
import BodyText from '../../ui/BodyText'
import Button from '../../ui/Button'
import OfferCard from '../../ui/OfferCard'
// Contexts
import { CopyContext } from '../../contexts/Copy'
import { ConfigContext } from '../../contexts/Config'
import { FormDataContext } from '../../contexts/FormData'
import { PopupContext } from '../../contexts/Popup'
// Helpers
import { parseCopy } from '../../helpers/parseCopy'

function BasicOffers({ onNext }: any) {
    // Contexts
    const [copy]: any = useContext(CopyContext)
    const [config]: any = useContext(ConfigContext)
    const [formData, formDataDispatch]: any = useContext(FormDataContext)
    const [, popupDispatch]: any = useContext(PopupContext)

    return (
        <Page>
            <Main>
                <Container>
                    <HeaderText name="basicOffersHeader">
                        <StyleCopy>
                            {parseCopy(copy?.steps?.basicOffers?.title, {
                                config,
                                formData,
                                copy: copy?.steps?.basicOffers,
                            })}
                        </StyleCopy>
                    </HeaderText>
                    <BodyText name="basicOffersSubHeader">
                        <StyleCopy>
                            {parseCopy(copy?.steps?.basicOffers?.subHeader, {
                                config,
                                formData,
                                copy: copy?.steps?.basicOffers,
                            })}
                        </StyleCopy>
                    </BodyText>
                    <BodyText name="basicOffersNeedHelper">
                        <StyleCopy>
                            {parseCopy(copy?.steps?.basicOffers?.needHelp, {
                                config,
                                formData,
                                copy: copy?.steps?.basicOffers,
                            })}
                        </StyleCopy>
                    </BodyText>
                    {copy?.steps?.basicOffers?.offers?.map((offer: any) => {
                        return (
                            // eslint-disable-next-line react/jsx-key
                            <OfferCard
                                priceText={offer?.priceText}
                                buttonCopy={offer?.buttonCopy}
                                shortDescription={offer?.shortDescription}
                                sections={offer?.sections}
                                showMoreCopy={offer?.showMoreCopy}
                                showLessCopy={offer?.showLessCopy}
                                isAcp={formData.isAcpTransferable}
                                strikedPriceText={offer?.strikedPrice}
                                giftCard={offer?.giftCard}
                                onClick={() => {
                                    formDataDispatch({
                                        type: 'SET_FIELD',
                                        payload: { name: 'selectedPlan', value: offer?.value },
                                    })
                                    onNext()
                                }}
                            />
                        )
                    })}
                    <Button
                        name="basicOffersDisclaimers"
                        onClick={() => {
                            popupDispatch({
                                type: 'ADD_POPUP',
                                payload: {
                                    name: 'basicOffersStepDisclaimers',
                                    componentToRender: 'basicOffersDisclaimer',
                                },
                            })
                        }}
                    >
                        <StyleCopy>
                            {parseCopy(copy?.steps?.basicOffers?.disclaimerText, {
                                config,
                                formData,
                                copy: copy?.steps?.basicOffers,
                            })}
                        </StyleCopy>
                    </Button>
                </Container>
            </Main>
        </Page>
    )
}

export default BasicOffers
