/* eslint-disable react-hooks/exhaustive-deps */
// Packages
import React, { useEffect, useContext, useState } from 'react'
import { twMerge } from 'tailwind-merge'
// Contexts
import { ValidationContext } from '../contexts/Validation'
import { CopyContext } from '../contexts/Copy'
import { ConfigContext } from '../contexts/Config'
import { StylesContext } from '../contexts/Styles'
// Helpers
import { validateSingleField } from '../helpers/validation/main'
import stringReplace from '../helpers/stringReplace'
import { capitalizeFirstLetter } from '../helpers/string'
import ConditionalRender from '../components/ConditionalRender'

// Interface
// {
//     autoComplete: string
//     children: React.ReactElement | string
//     className: string
//     inputClassName: string
//     disabled: boolean | undefined
//     name: string
//     onChange: Function
//     placeholder: string
//     isNumberType: boolean
// }

function TextField({
    step = null,
    autoComplete = 'on',
    children,
    className = '',
    inputClassName = '',
    disabled = undefined,
    name,
    onChange = () => {},
    placeholder,
    isNumberType,
    validationFunctions = [],
    formDataKey = '',
    disableOnChangeValidation = false,
    initialValue = '',
    subLabel = '',
    setValueTo,
    staticValue,
    dropdown = [],
    disableDropdown = false,
}: any) {
    // Contexts
    const [validation, validationDispatch]: any = useContext(ValidationContext)
    const [copy]: any = useContext(CopyContext)
    const [config]: any = useContext(ConfigContext)
    const [styles]: any = useContext(StylesContext)
    // States
    const [createdValidation, setCreatedValidation] = useState(false)
    const [value, setValue] = useState(initialValue)
    const [waitToValidate, setWaitToValidate] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    useEffect(() => {
        if (!config?.tenantConfig?.featureFlags?.[`disable${capitalizeFirstLetter(name)}TextField`]) {
            if ((step || step === 0) && name && !createdValidation) {
                validationDispatch({
                    type: 'ADD_FIELD',
                    payload: {
                        step,
                        name,
                        formDataKey,
                        functions: validationFunctions,
                    },
                })
                setCreatedValidation(true)
            }
        }
    }, [config?.tenantConfig?.featureFlags, createdValidation, formDataKey, name, step, validationFunctions])

    useEffect(() => {
        if (setValueTo) {
            setValue(setValueTo === -1 ? '' : setValueTo)
            onChange(setValueTo === -1 ? '' : setValueTo, true)
            if (!waitToValidate) {
                setWaitToValidate(true)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setValueTo])

    useEffect(() => {
        const asyncWrapper = async () => {
            const { message, metaData } = await validateSingleField(value, validationFunctions)
            setErrorMessage(
                stringReplace(copy?.validationErrors?.[message] ? copy?.validationErrors?.[message] : '', metaData)
            )
        }

        if (
            waitToValidate &&
            !disableOnChangeValidation &&
            !config?.tenantConfig?.featureFlags?.[`disable${capitalizeFirstLetter(name)}TextField`]
        ) {
            asyncWrapper()
        }
    }, [
        value,
        validationFunctions,
        waitToValidate,
        disableOnChangeValidation,
        copy?.validationErrors,
        config?.tenantConfig?.featureFlags,
        name,
    ])

    useEffect(() => {
        if (validation?.errors?.errorMessages?.[name] || validation?.errors?.errorMessages?.[name] === '') {
            if (errorMessage !== validation.errors.errorMessages[name]) {
                let message = validation.errors.errorMessages[name]
                if (message !== '') {
                    message = stringReplace(
                        copy?.validationErrors?.[validation.errors.errorMessages[name]]
                            ? copy?.validationErrors?.[validation.errors.errorMessages[name]]
                            : '',
                        validation.errors.metaDatas[name]
                    )
                }
                setErrorMessage(message)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [validation.errors])

    if (config?.tenantConfig?.featureFlags?.[`disable${capitalizeFirstLetter(name)}TextField`]) {
        return null
    }

    return (
        // eslint-disable-next-line jsx-a11y/label-has-associated-control
        <label>
            <p className="font-800">{children}</p>
            <div className={subLabel ? className : 'hidden'}>{subLabel}</div>
            <input
                value={value}
                autoComplete={autoComplete}
                className="h-14 pl-4 border-2 border-zinc-500 rounded-xl text-base font-400 w-full pt-1 md:text-lg md:mb-3 active:border-blue-500 hover:border-blue-500"
                disabled={disabled}
                name={name}
                placeholder={placeholder}
                type={isNumberType ? 'tel' : 'text'}
                onChange={(event) => {
                    if (!(staticValue && value !== staticValue && event.target.value.length < value.length)) {
                        if (
                            (staticValue && event.target.value === staticValue) ||
                            (staticValue && value === staticValue && event.target.value.length < value.length)
                        ) {
                            return
                        }
                    }
                    setValue(event.target.value)
                    onChange(event.target.value)
                    if (!waitToValidate) {
                        setWaitToValidate(true)
                    }
                }}
            />
            <ConditionalRender condition={dropdown.length > 0 && !disableDropdown}>
                <div>{dropdown}</div>
            </ConditionalRender>
            <ConditionalRender condition={errorMessage}>
                <div
                    className={twMerge(
                        styles?.ui?.TextField?.error?.mobile,
                        styles?.ui?.TextField?.error?.tablet,
                        styles?.ui?.TextField?.error?.desktop,
                        inputClassName
                    )}
                >
                    {errorMessage}
                </div>
            </ConditionalRender>
        </label>
    )
}

export default TextField
