// Packages
import React, { useContext } from 'react'
// Components
import StyleCopy from '../../components/StyleCopy'
// UI
import Page from '../../ui/Page'
import Main from '../../ui/Main'
import Container from '../../ui/Container'
import HeaderText from '../../ui/HeaderText'
import BodyText from '../../ui/BodyText'
import Button from '../../ui/Button'
// Contexts
import { CopyContext } from '../../contexts/Copy'
import { ConfigContext } from '../../contexts/Config'
import { FormDataContext } from '../../contexts/FormData'
// Helpers
import { parseCopy } from '../../helpers/parseCopy'

function BasicFallbackSuccess() {
    // Contexts
    const [copy]: any = useContext(CopyContext)
    const [config]: any = useContext(ConfigContext)
    const [formData]: any = useContext(FormDataContext)

    return (
        <Page>
            <Main>
                <Container>
                    <HeaderText name="basicFallbackSuccessHeader">
                        <StyleCopy>
                            {parseCopy(copy?.steps?.basicFallbackSuccess?.title, { config, formData })}
                        </StyleCopy>
                    </HeaderText>
                    <BodyText name="basicFallbackSuccessSubHeader">
                        <StyleCopy>
                            {parseCopy(copy?.steps?.basicFallbackSuccess?.subHeader, { config, formData })}
                        </StyleCopy>
                    </BodyText>
                    <Button
                        name="basicFallbackSuccessCallButton"
                        onClick={() => {
                            window.open(
                                `tel:+1${parseCopy(copy?.steps?.basicFallbackSuccess?.callNowButton?.phoneNumber, { config, formData })}`
                            )
                        }}
                    >
                        <StyleCopy>
                            {parseCopy(copy?.steps?.basicFallbackSuccess?.callNowButton?.text, { config, formData })}
                        </StyleCopy>
                    </Button>
                </Container>
            </Main>
        </Page>
    )
}

export default BasicFallbackSuccess
