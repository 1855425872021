/* eslint-disable react-hooks/exhaustive-deps */
// Packages
import { useEffect, useContext } from 'react'
// Contexts
import { StylesContext } from '../contexts/Styles'
import { ConfigContext } from '../contexts/Config'
import { QueryParamsContext } from '../contexts/QueryParams'
import { LoadingBlockersContext } from '../contexts/LoadingBlockers'
// Helpers
import getConfigData from '../helpers/config/getConfigData'

function SetUpStyles({ formName }: { formName: string }) {
    // Contexts
    const [styles, stylesDispatch]: any = useContext(StylesContext)
    const [config]: any = useContext(ConfigContext)
    const [queryParams]: any = useContext(QueryParamsContext)
    const [, loadingBlockersDispatch]: any = useContext(LoadingBlockersContext)

    useEffect(() => {
        if (Object.keys(styles).length > 0) {
            loadingBlockersDispatch({ type: 'REMOVE_PAGE_BLOCKER', payload: 'setUpStyles' })
        }
    }, [config.environment, styles])

    useEffect(() => {
        const asyncWrapper = async () => {
            loadingBlockersDispatch({ type: 'ADD_PAGE_BLOCKER', payload: 'setUpStyles' })
            getConfigData('styles.json', config.tenant, {
                formName,
                isMock: config?.isMock,
                configId: queryParams?.configId ? queryParams.configId : config.deployment,
                configEnvironment: queryParams?.configEnvironment ? queryParams.configEnvironment : config.account,
            }).then((data: any) => {
                stylesDispatch({
                    type: 'SET',
                    payload: data,
                })
                const script: any = document.createElement('script')
                script.type = 'text/javascript'
                script.innerHTML = `
                    tailwind.config = {
                        theme: ${JSON.stringify(data?.theme)}
                    }
                `

                const style: any = document.createElement('style')
                style.type = 'text/tailwindcss'

                data?.css?.forEach((css: string) => {
                    style.innerHTML += css
                })

                document.head.appendChild(script)
                document.head.appendChild(style)
            })
        }

        asyncWrapper()
    }, [
        config.account,
        config.deployment,
        config?.isMock,
        config.tenant,
        formName,
        queryParams.configEnvironment,
        queryParams.configId,
        queryParams.isMock,
    ])

    return null
}

export default SetUpStyles
