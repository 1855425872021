// Packages
import React, { useContext } from 'react'
// Components
import StyleCopy from '../../components/StyleCopy'
// UI
import Page from '../../ui/Page'
import Main from '../../ui/Main'
import Container from '../../ui/Container'
import HeaderText from '../../ui/HeaderText'
import BodyText from '../../ui/BodyText'
import TextField from '../../ui/TextField'
import Checkbox from '../../ui/Checkbox'
import DatePicker from '../../ui/DatePicker'
// Helpers
import {
    requireNonEmptyValue,
    requireOnlyValidNladCharacters,
    requireMoreThanXCharacters,
    requireNotToBeAllSpaces,
    requireValidEmail,
    requireOnlyNumbers,
    requireXCharacters,
    requireCompleteDate,
} from '../../helpers/validation/unitFunctions'
import { parseCopy } from '../../helpers/parseCopy'
// Contexts
import { FormDataContext } from '../../contexts/FormData'
import { CopyContext } from '../../contexts/Copy'
import { ConfigContext } from '../../contexts/Config'

function BasicPii({ step }: any) {
    // Contexts
    const [formData, formDataDispatch]: any = useContext(FormDataContext)
    const [copy]: any = useContext(CopyContext)
    const [config]: any = useContext(ConfigContext)

    return (
        <Page>
            <Main>
                <Container className="flex flex-col">
                    <HeaderText name="basicPiiHeader">
                        <StyleCopy>
                            {parseCopy(copy?.steps?.basicPii?.title, { config, formData, copy: copy?.steps?.basicPii })}
                        </StyleCopy>
                    </HeaderText>
                    <BodyText name="basicPiiSubHeader">
                        <StyleCopy>
                            {parseCopy(copy?.steps?.basicPii?.subHeader, {
                                config,
                                formData,
                                copy: copy?.steps?.basicPii,
                            })}
                        </StyleCopy>
                    </BodyText>
                    <TextField
                        step={step}
                        validationFunctions={[
                            (value: string) => {
                                return requireMoreThanXCharacters(value, 2, 'letters')
                            },
                            requireNotToBeAllSpaces,
                            requireOnlyValidNladCharacters,
                            requireNonEmptyValue,
                        ]}
                        formDataKey="firstName"
                        name="firstName"
                        subLabel={
                            <StyleCopy>
                                {parseCopy(copy?.steps?.basicPii?.firstName?.subLabel, {
                                    config,
                                    formData,
                                    copy: copy?.steps?.basicPii,
                                })}
                            </StyleCopy>
                        }
                        initialValue={formData.firstName}
                        onChange={(value: any) => {
                            formDataDispatch({
                                type: 'SET_FIELD',
                                payload: { name: 'firstName', value },
                            })
                        }}
                    >
                        <StyleCopy>
                            {parseCopy(copy?.steps?.basicPii?.firstName?.label, {
                                config,
                                formData,
                                copy: copy?.steps?.basicPii,
                            })}
                        </StyleCopy>
                    </TextField>
                    <TextField
                        step={step}
                        validationFunctions={[
                            (value: string) => {
                                return requireMoreThanXCharacters(value, 2, 'letters')
                            },
                            requireNotToBeAllSpaces,
                            requireOnlyValidNladCharacters,
                            requireNonEmptyValue,
                        ]}
                        formDataKey="lastName"
                        name="lastName"
                        subLabel={
                            <StyleCopy>
                                {parseCopy(copy?.steps?.basicPii?.lastName?.subLabel, {
                                    config,
                                    formData,
                                    copy: copy?.steps?.basicPii,
                                })}
                            </StyleCopy>
                        }
                        initialValue={formData.lastName}
                        onChange={(value: any) => {
                            formDataDispatch({
                                type: 'SET_FIELD',
                                payload: { name: 'lastName', value },
                            })
                        }}
                    >
                        <StyleCopy>
                            {parseCopy(copy?.steps?.basicPii?.lastName?.label, {
                                config,
                                formData,
                                copy: copy?.steps?.basicPii,
                            })}
                        </StyleCopy>
                    </TextField>
                    <TextField
                        step={step}
                        validationFunctions={[requireValidEmail, requireNonEmptyValue]}
                        formDataKey="email"
                        name="email"
                        subLabel={
                            <StyleCopy>
                                {parseCopy(copy?.steps?.basicPii?.email?.subLabel, {
                                    config,
                                    formData,
                                    copy: copy?.steps?.basicPii,
                                })}
                            </StyleCopy>
                        }
                        initialValue={formData.email}
                        onChange={(value: any) => {
                            formDataDispatch({
                                type: 'SET_FIELD',
                                payload: { name: 'email', value },
                            })
                        }}
                    >
                        <StyleCopy>
                            {parseCopy(copy?.steps?.basicPii?.email?.label, {
                                config,
                                formData,
                                copy: copy?.steps?.basicPii,
                            })}
                        </StyleCopy>
                    </TextField>
                    <TextField
                        step={step}
                        type="tel"
                        validationFunctions={[
                            (value: string) => requireXCharacters(value, 10),
                            requireOnlyNumbers,
                            requireNonEmptyValue,
                        ]}
                        formDataKey="phoneNumber"
                        name="phoneNumber"
                        subLabel={
                            <StyleCopy>
                                {parseCopy(copy?.steps?.basicPii?.phoneNumber?.subLabel, {
                                    config,
                                    formData,
                                    copy: copy?.steps?.basicPii,
                                })}
                            </StyleCopy>
                        }
                        initialValue={formData.phoneNumber}
                        onChange={(value: any) => {
                            formDataDispatch({
                                type: 'SET_FIELD',
                                payload: { name: 'phoneNumber', value },
                            })
                        }}
                    >
                        <StyleCopy>
                            {parseCopy(copy?.steps?.basicPii?.phoneNumber?.label, {
                                config,
                                formData,
                                copy: copy?.steps?.basicPii,
                            })}
                        </StyleCopy>
                    </TextField>
                    <DatePicker
                        step={step}
                        validationFunctions={[requireCompleteDate]}
                        formDataKey="birthDate"
                        name="birthDate"
                        subLabel={
                            <StyleCopy>
                                {parseCopy(copy?.steps?.basicPii?.birthDay?.subLabel, {
                                    config,
                                    formData,
                                    copy: copy?.steps?.basicPii,
                                })}
                            </StyleCopy>
                        }
                        initialValue={formData.birthDate}
                        onChange={(value: string) => {
                            formDataDispatch({
                                type: 'SET_FIELD',
                                payload: { name: 'birthDate', value },
                            })
                        }}
                    >
                        <StyleCopy>
                            {parseCopy(copy?.steps?.basicPii?.birthDay?.label, {
                                config,
                                formData,
                                copy: copy?.steps?.basicPii,
                            })}
                        </StyleCopy>
                    </DatePicker>
                    <Checkbox
                        step={step}
                        validationFunctions={[requireNonEmptyValue]}
                        formDataKey="consentToContact"
                        name="consentToContact"
                        initialValue={formData.consentToContact}
                        onChange={(value: boolean) => {
                            formDataDispatch({
                                type: 'SET_FIELD',
                                payload: { name: 'consentToContact', value },
                            })
                        }}
                    >
                        <StyleCopy>
                            {parseCopy(copy?.steps?.basicPii?.contactConsent, {
                                config,
                                formData,
                                copy: copy?.steps?.basicPii,
                            })}
                        </StyleCopy>
                    </Checkbox>
                </Container>
            </Main>
        </Page>
    )
}

export default BasicPii
