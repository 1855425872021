/* eslint-disable react/prop-types */
// Packages
import React, { useContext } from 'react'
// Contexts
import { StylesContext } from '../contexts/Styles'
import { PopupContext } from '../contexts/Popup'

function Link({ className = '', children, href }: { [key: string]: any }) {
    // Contexts
    const [styles]: any = useContext(StylesContext)
    const [, popupDispatch]: any = useContext(PopupContext)

    if (href.startsWith('popup:')) {
        return (
            <button
                type="button"
                className={`${className} ${styles?.ui?.BodyText?.mobile} ${styles?.ui?.BodyText?.tablet} ${styles?.ui?.BodyText?.desktop}`}
                onClick={() => {
                    popupDispatch({
                        type: 'ADD_POPUP',
                        payload: {
                            name: href.split(':')[1],
                            componentToRender: href.split(':')[1],
                        },
                    })
                }}
            >
                {children}
            </button>
        )
    }

    return (
        <a
            className={`${className} ${styles?.ui?.BodyText?.mobile} ${styles?.ui?.BodyText?.tablet} ${styles?.ui?.BodyText?.desktop}`}
            href={href}
            target="_blank"
            rel="noreferrer"
        >
            {children}
        </a>
    )
}

export default Link
