/* eslint-disable react-hooks/exhaustive-deps */
// Packages
import { useEffect, useContext } from 'react'
// Contexts
import { ConfigContext } from '../contexts/Config'
import { QueryParamsContext } from '../contexts/QueryParams'
import { LoadingBlockersContext } from '../contexts/LoadingBlockers'
import { InstanceContext } from '../contexts/Instance'
// Helpers
import getConfigData from '../helpers/config/getConfigData'

function SetUpConfig({ formName }: { formName: string }) {
    // Contexts
    const [config, configDispatch]: any = useContext(ConfigContext)
    const [queryParams]: any = useContext(QueryParamsContext)
    const [, loadingBlockersDispatch]: any = useContext(LoadingBlockersContext)
    const [instance]: any = useContext(InstanceContext)

    // Environment
    useEffect(() => {
        if (config?.loaded?.environment) {
            loadingBlockersDispatch({ type: 'REMOVE_PAGE_BLOCKER', payload: 'setUpEnvironment' })
        }
    }, [config?.loaded?.environment])

    useEffect(() => {
        const asyncWrapper = async () => {
            loadingBlockersDispatch({ type: 'ADD_PAGE_BLOCKER', payload: 'setUpEnvironment' })
            getConfigData('environment.json', config.tenant, {
                formName,
                isMock: config?.isMock,
                environmentId: queryParams.environmentId,
                configId: queryParams?.configId ? queryParams.configId : config.deployment,
                configEnvironment: queryParams?.configEnvironment ? queryParams.configEnvironment : config.account,
            }).then((data: { [key: string]: any }) => {
                const payload = { ...data, ...instance?.contexts?.config?.environment }

                configDispatch({
                    type: 'SET_ENVIRONMENT',
                    payload,
                })
            })
        }

        asyncWrapper()
    }, [
        config.account,
        config.deployment,
        config?.isMock,
        config.tenant,
        formName,
        instance?.contexts?.config?.environment,
        queryParams.configEnvironment,
        queryParams.configId,
        queryParams.environmentId,
        queryParams.isMock,
    ])

    // Form Name
    useEffect(() => {
        if (config?.loaded?.formName) {
            loadingBlockersDispatch({ type: 'REMOVE_PAGE_BLOCKER', payload: 'setUpFormName' })
        }
    }, [config?.loaded?.formName, loadingBlockersDispatch])

    useEffect(() => {
        loadingBlockersDispatch({ type: 'ADD_PAGE_BLOCKER', payload: 'setUpFormName' })
        if (config.splitPath === 'a' && instance?.metaData?.splitTest?.a?.formName !== undefined) {
            configDispatch({
                type: 'SET_FORM_NAME',
                payload: instance.metaData.splitTest.a.formName,
            })
        } else if (config.splitPath === 'b' && instance?.metaData?.splitTest?.b?.formName !== undefined) {
            configDispatch({
                type: 'SET_FORM_NAME',
                payload: instance.metaData.splitTest.b.formName,
            })
        } else {
            configDispatch({
                type: 'SET_FORM_NAME',
                payload: instance?.contexts?.config?.formName ? instance.contexts.config.formName : formName,
            })
        }
    }, [
        config.splitPath,
        formName,
        instance?.contexts?.config?.formName,
        instance?.metaData?.splitTest?.a?.formName,
        instance?.metaData?.splitTest?.b?.formName,
    ])

    return null
}

export default SetUpConfig
