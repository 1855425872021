// Packages
import React from 'react'
// Stores
import QueryParamsStore from '../contexts/QueryParams'
import ConfigStore from '../contexts/Config'
import InitialLoadBlockersStore from '../contexts/InitialLoadBlockers'
import UserStore from '../contexts/User'

function RootContexts({ children }: { children: React.ReactElement | Array<React.ReactElement> }) {
    return (
        <QueryParamsStore>
            <InitialLoadBlockersStore>
                <ConfigStore>
                    <UserStore>{children}</UserStore>
                </ConfigStore>
            </InitialLoadBlockersStore>
        </QueryParamsStore>
    )
}

export default RootContexts
