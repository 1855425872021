const validate = async (
    step: Number,
    formData: { [index: string]: any },
    validation: { [index: string]: { name: string; functions: Array<Function>; formDataKey: string } }
) => {
    const stepsErrored: { [index: string]: boolean } = {}
    let isStepValid = true
    const errorMessages: { [index: string]: string } = {}
    const metaDatas: { [index: string]: string } = {}

    if (validation) {
        const { fields }: any = validation
        const arrayOfFields: Array<string> = Object.keys(fields)
        const numberOfFields = arrayOfFields.length

        for (let i = 0; i < numberOfFields; i += 1) {
            const { name, functions, formDataKey, formDataKeys }: any = fields[arrayOfFields[i]]
            const numberOfFunctions = functions.length

            for (let j = 0; j < numberOfFunctions; j += 1) {
                let parameters = null

                if (formDataKey) {
                    parameters = formData[formDataKey]
                } else {
                    parameters = { formData, formDataKeys }
                }

                // eslint-disable-next-line no-await-in-loop
                const { isValid, message, metaData, erroredName } = await functions[j](parameters)

                if (formDataKey) {
                    errorMessages[name] = message
                    metaDatas[name] = metaData
                    if (!isValid) {
                        isStepValid = false
                        stepsErrored[name] = true
                        break
                    }
                } else {
                    errorMessages[erroredName] = message
                    metaDatas[erroredName] = metaData
                    if (!isValid) {
                        isStepValid = false
                        stepsErrored[erroredName] = true
                        break
                    }
                }
            }
        }

        return { isValid: isStepValid, stepsErrored, errorMessages, metaDatas }
    }

    return { isValid: isStepValid, stepsErrored, errorMessages, metaDatas }
}

export const validateSingleField = async (value: any, functions: Array<Function>) => {
    let isStepValid = true
    let errorMessage = ''
    let returnMetaData = {}

    for (let j = 0; j < functions.length; j += 1) {
        // eslint-disable-next-line no-await-in-loop
        const { isValid, message, metaData } = await functions[j](value)

        if (!isValid) {
            isStepValid = false
            errorMessage = message
            returnMetaData = metaData
        }
    }

    return { isValid: isStepValid, message: errorMessage, metaData: returnMetaData }
}

export default validate
