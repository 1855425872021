/* eslint-disable react-hooks/exhaustive-deps */
// Packages
import React, { useEffect, useContext } from 'react'

// Stores
import StepsStore from '../../contexts/Steps'
import ValidationStore from '../../contexts/Validation'
import FormDataStore from '../../contexts/FormData'
import LoadingBlockersStore from '../../contexts/LoadingBlockers'
import CopyStore from '../../contexts/Copy'
import StyleStore from '../../contexts/Styles'
import PopupStore from '../../contexts/Popup'
import { UserContext } from '../../contexts/User'
// Components
import SetUpSteps from '../../components/SetUpSteps'
import SetUpConfig from '../../components/SetUpConfig'
// import Navigation from '../../components/Navigation'
// import Navigation from '../../components/Navigation'
import DisplayLoadingBlocker from '../../components/DisplayLoadingBlocker'
import DisplayLoadingPageBlocker from '../../components/DisplayLoadingPageBlocker'
import SetUpCopy from '../../components/SetUpCopy'
import SetUpStyles from '../../components/SetUpStyles'
import SetUpFormData from '../../components/SetUpFormData'
import DbTracking from '../../components/DbTracking'
import DisplayPopup from '../../components/DisplayPopup'
// UI
import Favicon from '../../ui/Favicon'

// steps
import LoginPage from '../../steps/LoginForm'

function LoginForm({ config }: any) {
    const [, userReducer]: any = useContext(UserContext)
    useEffect(() => {
        userReducer({
            type: 'SET',
            payload: {
                currentPage: 'login',
            },
        })
    }, [])
    return (
        <StepsStore>
            <ValidationStore>
                <FormDataStore>
                    <LoadingBlockersStore>
                        <CopyStore>
                            <StyleStore>
                                <PopupStore>
                                    <DisplayLoadingBlocker />
                                    <SetUpCopy formName={config} />
                                    <SetUpSteps formName={config} />
                                    <SetUpConfig formName={config} />
                                    <SetUpStyles formName={config} />
                                    <SetUpFormData />
                                    <DbTracking />
                                    <DisplayLoadingPageBlocker>
                                        <Favicon />
                                        <DisplayPopup />
                                        <LoginPage />
                                    </DisplayLoadingPageBlocker>
                                </PopupStore>
                            </StyleStore>
                        </CopyStore>
                    </LoadingBlockersStore>
                </FormDataStore>
            </ValidationStore>
        </StepsStore>
    )
}

export default LoginForm
