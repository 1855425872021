const programsDefault: Array<{ code: string; name: string }> = [
    {
        code: 'SNAP',
        name: 'Supplemental Nutrition Assistance Program (SNAP) Food Stamps',
    },
    {
        code: 'MEDIC',
        name: 'Medicaid',
    },
    {
        code: 'SSI',
        name: 'Supplemental Security Income (SSI)',
    },
    {
        code: 'FPH',
        name: 'Federal Public Housing Assistance',
    },
    {
        code: 'VPSBP',
        name: 'Veterans Pension and Survivors Benefit Program',
    },
    {
        code: 'BIAGA',
        name: 'Bureau of Indian Affairs (BIA) General Assistance',
    },
    {
        code: 'TATAN',
        name: 'Tribal Temporary Assistance for Needy Families (Tribal TANF)',
    },
    {
        code: 'TFDP',
        name: 'Food Distribution Program on Indian Reservations (FDPIR)',
    },
    {
        code: 'HEADS',
        name: 'Tribal Head Start (only households that meet the income qualifying standard)',
    },
    {
        code: 'WIC',
        name: 'Special Supplemental Nutrition Program for Women, Infants, and Children (WIC)',
    },
    {
        code: 'FPG',
        name: 'Federal Pell Grant',
    },
    {
        code: 'NSL',
        name: 'School Lunch/Breakfast Program',
    },
]

const getHardcodedPrograms = (programs: Array<{ code: string; name: string }> = programsDefault, n = -1) => {
    if (n || n === 0) {
        return programs.slice(0, n)
    }

    return programs
}

export default getHardcodedPrograms
