// Packages
import React, { useContext } from 'react'
// Components
import StyleCopy from '../../components/StyleCopy'
// UI
import Page from '../../ui/Page'
import Main from '../../ui/Main'
import Container from '../../ui/Container'
import HeaderText from '../../ui/HeaderText'
import BodyText from '../../ui/BodyText'
import Button from '../../ui/Button'
// Contexts
import { CopyContext } from '../../contexts/Copy'
import { ConfigContext } from '../../contexts/Config'
import { FormDataContext } from '../../contexts/FormData'
// Helpers
import { parseCopy } from '../../helpers/parseCopy'

function GenericSuccess() {
    // Contexts
    const [copy]: any = useContext(CopyContext)
    const [config]: any = useContext(ConfigContext)
    const [formData]: any = useContext(FormDataContext)

    return (
        <Page>
            <Main>
                <Container>
                    <HeaderText name="genericSuccessHeader">
                        <StyleCopy>
                            {parseCopy(copy?.steps?.genericSuccess?.title, {
                                config,
                                formData,
                                copy: copy?.steps?.genericSuccess,
                            })}
                        </StyleCopy>
                    </HeaderText>
                    <BodyText name="genericSuccessSubHeader">
                        <StyleCopy>
                            {parseCopy(copy?.steps?.genericSuccess?.subHeader, {
                                config,
                                formData,
                                copy: copy?.steps?.genericSuccess,
                            })}
                        </StyleCopy>
                    </BodyText>
                    <div className="flex">
                        <BodyText name="genericSuccessOfferTitle">
                            <StyleCopy>
                                {parseCopy(copy?.steps?.genericSuccess?.offer?.title, {
                                    config,
                                    formData,
                                    copy: copy?.steps?.genericSuccess,
                                })}
                            </StyleCopy>
                        </BodyText>
                        <BodyText name="genericSuccessOfferPrice">
                            <StyleCopy>
                                {parseCopy(copy?.steps?.genericSuccess?.offer?.price, {
                                    config,
                                    formData,
                                    copy: copy?.steps?.genericSuccess,
                                })}
                            </StyleCopy>
                        </BodyText>
                    </div>
                    <Button
                        name="genericSuccessCallNow"
                        onClick={() => {
                            window.open(
                                `tel:+1${parseCopy(copy?.steps?.genericSuccess?.callNowButton?.phoneNumber, {
                                    config,
                                    formData,
                                    copy: copy?.steps?.genericSuccess,
                                })}`
                            )
                        }}
                    >
                        <StyleCopy>
                            {parseCopy(copy?.steps?.genericSuccess?.callNowButton?.text, {
                                config,
                                formData,
                                copy: copy?.steps?.genericSuccess,
                            })}
                        </StyleCopy>
                    </Button>
                    <BodyText name="genericSuccessFooter">
                        <StyleCopy>
                            {parseCopy(copy?.steps?.genericSuccess?.footer, {
                                config,
                                formData,
                                copy: copy?.steps?.genericSuccess,
                            })}
                        </StyleCopy>
                    </BodyText>
                </Container>
            </Main>
        </Page>
    )
}

export default GenericSuccess
