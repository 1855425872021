/* eslint-disable react/prop-types */
// Packages
import React, { useContext } from 'react'
// Contexts
import { PopupContext } from '../contexts/Popup'

function Popup({ className = '', children }: { [key: string]: any }) {
    // Contexts
    const [, popupDispatch]: any = useContext(PopupContext)

    return (
        <>
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
            <div
                className="fixed bg-zinc-700 top-0 left-0 w-screen h-screen opacity-65"
                onClick={() => {
                    popupDispatch({ type: 'REMOVE_POPUP' })
                }}
            />
            <div
                className={`${className} bottom-0 left-0 fixed w-screen bg-white px-6 py-4 h-fit max-h-[90vh] overflow-scroll`}
            >
                {children}
            </div>
        </>
    )
}

export default Popup
