// Backend
import utilities from './utilities'
import fcServiceabilityCheck from './fcServiceabilityCheck'
import smartyValidation from './smartyValidation'
import ibexServiceabilityCheck from './ibexServiceabilityCheck'

const stacks: { [key: string]: Function } = {
    utilities,
    fcServiceabilityCheck,
    smartyValidation,
    ibexServiceabilityCheck,
}

const makeBackendRequest = async (
    type: { stack: string; endpoint: string },
    request: {
        config: any
        formData: any
        body?: any
        queryParams?: any
        formDataDispatch?: any
        additionalCallStack?: any
        makeBackendRequest?: any
    }
) => {
    return stacks[type.stack](type.endpoint, request)
}

export default makeBackendRequest
