// Packages
import React, { useContext, useState, useEffect } from 'react'
import { signOut } from 'aws-amplify/auth'
import { useNavigate, NavLink } from 'react-router-dom'
// Contexts
import { ConfigContext } from '../contexts/Config'
import Container from '../ui/Container'
import Button from '../ui/Button'

// Create routes for each generated route
function Navigation() {
    // Contexts
    const [config, configDispatch]: any = useContext(ConfigContext)

    // State
    const [addressUrl, setAddressUrl] = useState('/address')
    const [callCenterStartUrl, setCallCenterStartUrl] = useState('/callCenterStart')
    const [loginUrl, setLoginUrl] = useState('/login')

    // helpers
    const navigate = useNavigate()

    useEffect(() => {
        if (config.account === 'dev') {
            setAddressUrl('/address/?isMock&devMode')
            setCallCenterStartUrl('/callCenterStart/?isMock&devMode')
            setLoginUrl('/login/?isMock&devMode')
        }
    }, [config.account])

    // TODO: Make Navigation ui and use the components here
    return (
        <nav className="container mx-auto w-screen flex justify-between min-h-10 mt-2">
            <Container className="container min-w-80 flex items-center">
                <NavLink
                    className="bg-white inline-block py-2 px-4 text-sm font-medium text-blue-700 rounded-t-lg border-b-2 border-blue-500 hover:text-blue-600 hover:bg-blue-50"
                    to={callCenterStartUrl}
                    onClick={() => {
                        configDispatch({
                            type: 'CLEAR_LOADED',
                        })
                    }}
                >
                    Inicio
                </NavLink>
                <NavLink
                    className="bg-white inline-block py-2 px-4 text-sm font-medium text-blue-700 rounded-t-lg border-b-2 border-blue-500 hover:text-blue-600 hover:bg-blue-50"
                    to={addressUrl}
                    onClick={() => {
                        configDispatch({
                            type: 'CLEAR_LOADED',
                        })
                    }}
                >
                    Address
                </NavLink>
            </Container>
            <Button
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                type="button"
                onClick={() => {
                    signOut()
                    configDispatch({
                        type: 'CLEAR_LOADED',
                    })
                    navigate(loginUrl)
                }}
            >
                Logout
            </Button>
        </nav>
    )
}

export default Navigation
