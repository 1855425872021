// Packages
import React, { useContext, useEffect, useRef } from 'react'
// UI
import Loading from '../../ui/LoadingBlocker'
// Contexts
import { ConfigContext } from '../../contexts/Config'
import { FormDataContext } from '../../contexts/FormData'
// Helpers
import makeBackendRequest from '../../helpers/backend'

function BasicServiceabilityCheck() {
    // Contexts
    const [config]: any = useContext(ConfigContext)
    const [formData, formDataDispatch]: any = useContext(FormDataContext)
    // Refs
    const dataFetch = useRef(false)

    useEffect(() => {
        if (dataFetch.current) return
        dataFetch.current = true
        makeBackendRequest({ stack: 'fcServiceabilityCheck', endpoint: 'main' }, { config, formData })
            .then((response) => response.json())
            .then((data) => {
                if (data.top_serviceable_company) {
                    formDataDispatch({
                        type: 'SET_FIELD',
                        payload: { name: 'topServiceableCompany', value: data.top_serviceable_company },
                    })
                    formDataDispatch({
                        type: 'SET_FIELD',
                        payload: { name: 'eligibleProvidersString', value: data.serviceability_array.join('|') },
                    })
                    formDataDispatch({
                        type: 'SET_FIELD',
                        payload: { name: 'eligibleProviders', value: data.serviceability_array },
                    })
                }
            })
        // onNext()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <Loading />
}

export default BasicServiceabilityCheck
