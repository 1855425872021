const getUrl = (environment: string) => {
    switch (environment) {
        case 'localhost':
            return 'http://localhost:3000/address_verification'
        case 'dev':
            return 'https://96ar7fxe0b.execute-api.us-east-1.amazonaws.com/prod/address_verification'
        case 'test':
            return 'https://ee4z4vsini.execute-api.us-east-1.amazonaws.com/prod/address_verification'
        default:
            return 'https://24n9akfcli.execute-api.us-east-1.amazonaws.com/prod/address_verification'
    }
}
const getApiKey = (environment: string): string => {
    switch (environment) {
        case 'localhost':
            return ''
        case 'dev':
            return process.env.REACT_APP_DEV_SMARTY_KEY ? process.env.REACT_APP_DEV_SMARTY_KEY : ''
        case 'test':
            return process.env.REACT_APP_TEST_SMARTY_KEY ? process.env.REACT_APP_TEST_SMARTY_KEY : ''
        default:
            return process.env.REACT_APP_PROD_SMARTY_KEY ? process.env.REACT_APP_PROD_SMARTY_KEY : ''
    }
}

const main = async (request: { config: any; formData: any; valueKeys: any }) => {
    return fetch(`${getUrl(request.config.environment.smartyValidation)}/`, {
        method: 'POST',
        body: JSON.stringify({
            line_one: request?.formData?.[request?.valueKeys?.street],
            line_two: request?.formData?.[request?.valueKeys?.secondary],
            city: request?.formData?.[request?.valueKeys?.city],
            state: request?.formData?.[request?.valueKeys?.state],
            zip: request?.formData?.[request?.valueKeys?.zip],
            user_id: request.config?.ace_id,
        }),
        headers: {
            'x-api-key': getApiKey(request.config.environment.smartyValidation),
            Authorization: 'smarty',
        },
    })
}

const endpoints: { [key: string]: Function } = {
    main,
}

const smartyValidationMain = async (endpoint: string, request: { config: any; formData: any; valueKeys: any }) => {
    return endpoints[endpoint](request)
}

export default smartyValidationMain
