/* eslint-disable react-hooks/exhaustive-deps */
// Packages
import { useEffect, useContext } from 'react'
// Contexts
import { ConfigContext } from '../contexts/Config'
import { QueryParamsContext } from '../contexts/QueryParams'
import { LoadingBlockersContext } from '../contexts/LoadingBlockers'
import { FormDataContext } from '../contexts/FormData'
import { InstanceContext } from '../contexts/Instance'
// Helpers
import getConfigData from '../helpers/config/getConfigData'

function SetUpFormData() {
    // Contexts
    const [config]: any = useContext(ConfigContext)
    const [, formDataDispatch]: any = useContext(FormDataContext)
    const [queryParams]: any = useContext(QueryParamsContext)
    const [, loadingBlockersDispatch]: any = useContext(LoadingBlockersContext)
    const [instance]: any = useContext(InstanceContext)

    useEffect(() => {
        const asyncWrapper = async () => {
            loadingBlockersDispatch({ type: 'REMOVE_INPUT_BLOCKER', payload: 'setUpFormData' })
            if (queryParams.testUserId) {
                getConfigData('testUsers.json', config.tenant, {
                    testUserId: queryParams.testUserId,
                    isMock: config?.isMock,
                    environmentId: queryParams.environmentId,
                    configId: queryParams?.configId ? queryParams.configId : config.deployment,
                    configEnvironment: queryParams?.configEnvironment ? queryParams.configEnvironment : config.account,
                }).then((data) => {
                    formDataDispatch({ type: 'MERGE', payload: { ...data, ...instance?.contexts?.formData } })
                    loadingBlockersDispatch({ type: 'REMOVE_INPUT_BLOCKER', payload: 'setUpFormData' })
                })
            } else {
                loadingBlockersDispatch({ type: 'REMOVE_INPUT_BLOCKER', payload: 'setUpFormData' })
            }
        }

        asyncWrapper()
    }, [
        config.account,
        config.deployment,
        config?.isMock,
        config.tenant,
        instance?.contexts?.formData,
        queryParams.configEnvironment,
        queryParams.configId,
        queryParams.environmentId,
        queryParams.isMock,
        queryParams.testUserId,
    ])

    return null
}

export default SetUpFormData
