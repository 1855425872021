// Packages
import React from 'react'
// Steps
import BasicAddress from '../../steps/BasicAddress'
import BasicPii from '../../steps/BasicPii'
import HardcodedPrograms from '../../steps/HardcodedPrograms'
import BasicWelcomePage from '../../steps/BasicWelcomePage'
import BasicServiceabilityCheck from '../../steps/BasicServiceabilityCheck'
import BasicServiceabilityCheckBlocker from '../../steps/BasicServiceabilityCheckBlocker'
import AcpTransferQuestion from '../../steps/AcpTransferQuestion'
import InterimFormP2Branching from '../../steps/InterimFormP2Branching'
import InterimFormOptimumSuccess from '../../steps/InterimFormOptimumSuccess'
import BasicFallbackSuccess from '../../steps/BasicFallbackSuccess'
import GenericExistingCustomerQuestion from '../../steps/GenericExistingCustomerQuestion'
import GenericSuccess from '../../steps/GenericSuccess'
import BasicOffers from '../../steps/BasicOffers'
import BasicSubmitOrder from '../../steps/BasicSubmitOrder'
import OptimumOrderComplete from '../../steps/OptimumOrderComplete'
// Step Actions
import getStepActions from '../stepActionFunctions/stepActions'
// Types
import { Step } from '../types/steps'

const stepNameMapObject: { [index: string]: Step } = {
    basicWelcomePage: {
        component: <BasicWelcomePage />,
        disableButtons: true,
        disableNextButton: false,
        disablePreviousButton: false,
        disableProgressBar: true,
        onAction: getStepActions({ key: 'defaultAction' }),
    },
    hardcodedPrograms: {
        component: <HardcodedPrograms />,
        disableButtons: true,
        disableNextButton: false,
        disablePreviousButton: false,
        disableProgressBar: false,
        onAction: getStepActions({ key: 'defaultAction' }),
    },
    basicAddress: {
        component: <BasicAddress />,
        disableButtons: false,
        disableNextButton: false,
        disablePreviousButton: false,
        disableProgressBar: false,
        onAction: getStepActions({ key: 'basicAddress' }),
    },
    basicServiceabilityCheck: {
        component: <BasicServiceabilityCheck />,
        disableButtons: false,
        disableNextButton: false,
        disablePreviousButton: false,
        disableProgressBar: true,
        onAction: getStepActions({ key: 'defaultAction' }),
    },
    basicServiceabilityCheckBlocker: {
        component: <BasicServiceabilityCheckBlocker />,
        disableButtons: true,
        disableNextButton: false,
        disablePreviousButton: false,
        disableProgressBar: true,
        onAction: getStepActions({ key: 'defaultAction' }),
    },
    basicPii: {
        component: <BasicPii />,
        disableButtons: false,
        disableNextButton: false,
        disablePreviousButton: false,
        disableProgressBar: false,
        onAction: getStepActions({ key: 'defaultAction' }),
    },
    acpTransferQuestion: {
        component: <AcpTransferQuestion />,
        disableButtons: true,
        disableNextButton: false,
        disablePreviousButton: false,
        disableProgressBar: false,
        onAction: getStepActions({ key: 'defaultAction' }),
    },
    interimFormP2Branching: {
        component: <InterimFormP2Branching />,
        disableButtons: true,
        disableNextButton: false,
        disablePreviousButton: false,
        disableProgressBar: true,
        onAction: getStepActions({ key: 'interimFormP2Branching' }),
    },
    interimFormOptimumSuccess: {
        component: <InterimFormOptimumSuccess />,
        disableButtons: true,
        disableNextButton: false,
        disablePreviousButton: false,
        disableProgressBar: false,
        onAction: getStepActions({ key: 'defaultAction' }),
    },
    basicFallbackSuccess: {
        component: <BasicFallbackSuccess />,
        disableButtons: true,
        disableNextButton: false,
        disablePreviousButton: false,
        disableProgressBar: true,
        onAction: getStepActions({ key: 'defaultAction' }),
    },
    genericExistingCustomerQuestion: {
        component: <GenericExistingCustomerQuestion />,
        disableButtons: true,
        disableNextButton: false,
        disablePreviousButton: false,
        disableProgressBar: false,
        onAction: getStepActions({ key: 'defaultAction' }),
    },
    genericSuccess: {
        component: <GenericSuccess />,
        disableButtons: true,
        disableNextButton: false,
        disablePreviousButton: false,
        disableProgressBar: false,
        onAction: getStepActions({ key: 'defaultAction' }),
    },
    basicOffers: {
        component: <BasicOffers />,
        disableButtons: true,
        disableNextButton: false,
        disablePreviousButton: false,
        disableProgressBar: false,
        onAction: getStepActions({ key: 'defaultAction' }),
    },
    basicSubmitOrder: {
        component: <BasicSubmitOrder />,
        disableButtons: true,
        disableNextButton: false,
        disablePreviousButton: false,
        disableProgressBar: false,
        onAction: getStepActions({ key: 'defaultAction' }),
    },
    optimumOrderComplete: {
        component: <OptimumOrderComplete />,
        disableButtons: true,
        disableNextButton: false,
        disablePreviousButton: false,
        disableProgressBar: false,
        onAction: getStepActions({ key: 'defaultAction' }),
    },
    default: {
        component: null,
        disableButtons: true,
        disableNextButton: false,
        disablePreviousButton: false,
        disableProgressBar: false,
        onAction: () => {},
    },
}

const stepNameMap = ({ key }: { key: string }) =>
    stepNameMapObject?.[key] ? stepNameMapObject[key] : stepNameMapObject.default

export default stepNameMap
