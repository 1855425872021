// Packages
import React, { useContext } from 'react'
import Container from '../../ui/Container'
// Context
import { CopyContext } from '../../contexts/Copy'
// Components
import StyleCopy from '../../components/StyleCopy'
import HeaderText from '../../ui/HeaderText'

function CallCenterStart() {
    // Contexts
    const [copy]: any = useContext(CopyContext)

    return (
        <Container className="p-5">
            <HeaderText> {copy?.steps?.callCenterStart?.title}</HeaderText>
            <hr />
            <ul className="mx-auto flex flex-col w-screen">
                {copy?.steps?.callCenterStart?.bodyContentArray?.map((item: any) => {
                    return (
                        <li className="pt-2" key={item}>
                            <StyleCopy>{item}</StyleCopy>
                        </li>
                    )
                })}
            </ul>
        </Container>
    )
}

export default CallCenterStart
