/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/label-has-associated-control */
// Packages
import React, { useEffect, useContext, useState } from 'react'
// Components
import ConditionalRender from '../components/ConditionalRender'
// Contexts
import { ValidationContext } from '../contexts/Validation'
import { CopyContext } from '../contexts/Copy'
import { PopupContext } from '../contexts/Popup'
import { ConfigContext } from '../contexts/Config'
// Helpers
import { validateSingleField } from '../helpers/validation/main'
import stringReplace from '../helpers/stringReplace'
import { capitalizeFirstLetter } from '../helpers/string'

// Interface
// {
//     autoComplete: string
//     children: React.ReactElement | string
//     className: string
//     inputClassName: string
//     disabled: boolean | undefined
//     name: string
//     onChange: Function
//     placeholder: string
//     isNumberType: boolean
// }

function Checkbox({
    step = null,
    children,
    disabled = false,
    className = '',
    name,
    onChange = () => {},
    validationFunctions = [],
    formDataKey = '',
    disableOnChangeValidation = false,
    initialValue = false,
    popup,
}: any) {
    // Contexts
    const [validation, validationDispatch]: any = useContext(ValidationContext)
    const [copy]: any = useContext(CopyContext)
    const [, popupDispatch]: any = useContext(PopupContext)
    const [config]: any = useContext(ConfigContext)
    // States
    const [createdValidation, setCreatedValidation] = useState(false)
    const [value, setValue] = useState(initialValue)
    const [waitToValidate, setWaitToValidate] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    useEffect(() => {
        if (!config?.tenantConfig?.featureFlags?.[`disable${capitalizeFirstLetter(name)}CheckBox`]) {
            if ((step || step === 0) && name && !createdValidation) {
                validationDispatch({
                    type: 'ADD_FIELD',
                    payload: {
                        step,
                        name,
                        formDataKey,
                        functions: validationFunctions,
                    },
                })
                setCreatedValidation(true)
            }
        }
    }, [config?.tenantConfig?.featureFlags, createdValidation, formDataKey, name, step, validationFunctions])

    useEffect(() => {
        const asyncWrapper = async () => {
            const { message, metaData } = await validateSingleField(value, validationFunctions)
            setErrorMessage(
                stringReplace(copy?.validationErrors?.[message] ? copy?.validationErrors?.[message] : '', metaData)
            )
        }

        if (
            waitToValidate &&
            !disableOnChangeValidation &&
            !config?.tenantConfig?.featureFlags?.[`disable${capitalizeFirstLetter(name)}CheckBox`]
        ) {
            asyncWrapper()
        }
    }, [
        value,
        validationFunctions,
        waitToValidate,
        disableOnChangeValidation,
        copy?.validationErrors,
        config?.tenantConfig?.featureFlags,
        name,
    ])

    useEffect(() => {
        if (validation?.errors?.errorMessages?.[name]) {
            if (errorMessage !== validation.errors[name]) {
                setErrorMessage(
                    stringReplace(
                        copy?.validationErrors?.[validation.errors.errorMessages[name]]
                            ? copy?.validationErrors?.[validation.errors.errorMessages[name]]
                            : '',
                        validation.errors.metaDatas[name]
                    )
                )
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [validation.errors])

    if (config?.tenantConfig?.featureFlags?.[`disable${capitalizeFirstLetter(name)}CheckBox`]) {
        return null
    }

    return (
        <div>
            <label className={`my-4 flex gap-2 ${className}`}>
                <input
                    type="checkbox"
                    name={name}
                    checked={value}
                    onChange={() => {
                        if (!disabled) {
                            onChange(!value)
                            setValue(!value)
                            setWaitToValidate(true)
                        }
                    }}
                />
                {children}
                {errorMessage}
            </label>
            <ConditionalRender condition={popup}>
                <button
                    type="button"
                    onClick={() => {
                        popupDispatch({
                            type: 'ADD_POPUP',
                            payload: {
                                name: popup,
                                componentToRender: popup,
                            },
                        })
                    }}
                >
                    {' '}
                    ⓘ
                </button>
            </ConditionalRender>
        </div>
    )
}

export default Checkbox
