// Packages
import React, { useContext } from 'react'
// Contexts
import { ConfigContext } from '../contexts/Config'

function Loading() {
    const [config]: any = useContext(ConfigContext)

    const styles: { [key: string]: { blocker: string; loading: string } } = {
        freeconnect: {
            blocker: 'absolute bg-freeconnect-primary w-screen h-screen flex flex-col justify-center z-100',
            loading: 'text-freeconnect-secondary loading loading-spinner loading-xl mx-auto z-101',
        },
        default: {
            blocker: 'absolute bg-stone-300 w-screen h-screen flex flex-col justify-center z-100',
            loading: 'text-white loading loading-spinner loading-xl mx-auto z-101',
        },
    }

    return (
        <div className={styles?.[config?.tenant] ? styles[config.tenant].blocker : styles.default.blocker}>
            <span className={styles?.[config?.tenant] ? styles[config.tenant].loading : styles.default.loading} />
        </div>
    )
}

export default Loading
