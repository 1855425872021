// Packages
import React, { useContext } from 'react'
// Components
import StyleCopy from '../../components/StyleCopy'
// UI
import Page from '../../ui/Page'
import Main from '../../ui/Main'
import Container from '../../ui/Container'
import HeaderText from '../../ui/HeaderText'
import BodyText from '../../ui/BodyText'
import Button from '../../ui/Button'
// Contexts
import { CopyContext } from '../../contexts/Copy'
import { ConfigContext } from '../../contexts/Config'
import { FormDataContext } from '../../contexts/FormData'
// Helpers
import { parseCopy } from '../../helpers/parseCopy'

function BasicWelcomePage({ onNext }: any) {
    // Contexts
    const [copy]: any = useContext(CopyContext)
    const [config]: any = useContext(ConfigContext)
    const [formData]: any = useContext(FormDataContext)

    return (
        <Page>
            <Main>
                <Container>
                    <HeaderText name="basicWelcomePageHeader">
                        <StyleCopy>
                            {parseCopy(copy?.steps?.basicWelcomePage?.title, {
                                config,
                                formData,
                                copy: copy?.steps?.basicWelcomePage,
                            })}
                        </StyleCopy>
                    </HeaderText>
                    <BodyText name="basicWelcomePageSubHeader">
                        <StyleCopy>
                            {parseCopy(copy?.steps?.basicWelcomePage?.subHeader, {
                                config,
                                formData,
                                copy: copy?.steps?.basicWelcomePage,
                            })}
                        </StyleCopy>
                    </BodyText>
                    <Button
                        type="fill"
                        className="font-900"
                        name="basicWelcomePageNext"
                        onClick={() => {
                            onNext()
                        }}
                    >
                        <StyleCopy>
                            {parseCopy(copy?.steps?.basicWelcomePage?.button, {
                                config,
                                formData,
                                copy: copy?.steps?.basicWelcomePage,
                            })}
                        </StyleCopy>
                    </Button>
                </Container>
            </Main>
        </Page>
    )
}

export default BasicWelcomePage
