const getUrl = (environment: string) => {
    switch (environment) {
        case 'localhost':
            return 'http://localhost:3000'
        case 'dev':
            return 'https://wfk79psoll.execute-api.us-east-1.amazonaws.com/prod'
        case 'test':
            return 'https://ndz1cq9q7b.execute-api.us-east-1.amazonaws.com/prod'
        default:
            return 'https://auqskz8w51.execute-api.us-east-1.amazonaws.com/prod'
    }
}
const getApiKey = (environment: string): string => {
    switch (environment) {
        case 'localhost':
            return ''
        case 'dev':
            return process.env.REACT_APP_DEV_FC_SERVICEABILITY_KEY
                ? process.env.REACT_APP_DEV_FC_SERVICEABILITY_KEY
                : ''
        case 'test':
            return process.env.REACT_APP_TEST_FC_SERVICEABILITY_KEY
                ? process.env.REACT_APP_TEST_FC_SERVICEABILITY_KEY
                : ''
        default:
            return process.env.REACT_APP_PROD_FC_SERVICEABILITY_KEY
                ? process.env.REACT_APP_PROD_FC_SERVICEABILITY_KEY
                : ''
    }
}

const main = async (request: {
    config: any
    formData: any
    formDataDispatch: any
    additionalCallStack: any
    makeBackendRequest: any
}) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve) => {
        let functionIterations = 4
        const fcServiceabilityResponse = await fetch(`${getUrl(request.config.environment.fcServiceabilityCheck)}/`, {
            method: 'POST',
            body: JSON.stringify({
                name: '1234567890',
                line_one: request.formData.addressStreet,
                line_two: request.formData.addressSecondary,
                city: request.formData.addressCity,
                state: request.formData.addressState,
                zip: request.formData.addressZip,
                user_id: '1234567890',
                return_results: true,
                return_serviceability_array: true,
                param_path: `/Serviceability/InterimCombo/${request.config.tenant.charAt(0).toUpperCase() + request.config.tenant.slice(1)}`,
            }),
            headers: {
                'x-api-key': getApiKey(request.config.environment.fcServiceabilityCheck),
                Authorization: request.config.tenant,
            },
        })
        const fcsJson = await fcServiceabilityResponse.clone().json()
        if (fcsJson?.ibex_pk) {
            const intervalId = setInterval(async () => {
                try {
                    await request
                        .makeBackendRequest(
                            { stack: 'ibexServiceabilityCheck', endpoint: 'main' },
                            {
                                formData: request?.formData,
                                config: request.config,
                                body: {
                                    ibexKey: fcsJson?.ibex_pk,
                                },
                            }
                        )
                        .then((resData: any) => resData.clone().json())
                        .then((data: any) => {
                            if (functionIterations > 0) {
                                if (data?.top_serviceable) {
                                    clearInterval(intervalId)
                                    if (data?.top_serviceable !== 'N/A') {
                                        resolve({
                                            top_serviceable_company: data?.top_serviceable,
                                            serviceability_array: data?.providers,
                                        })
                                    } else {
                                        resolve({
                                            top_serviceable_company: fcsJson?.top_serviceable_company,
                                            serviceability_array: data?.providers,
                                        })
                                    }
                                }
                            } else {
                                clearInterval(intervalId)
                                resolve({
                                    top_serviceable_company: 'N/A',
                                    serviceability_array: data?.providers || [],
                                })
                            }
                        })
                    functionIterations -= 1
                } catch (error) {
                    clearInterval(intervalId)
                    resolve(fcServiceabilityResponse)
                }
            }, 5000)
        } else {
            resolve(fcServiceabilityResponse)
        }
    })
}

const endpoints: { [key: string]: Function } = {
    main,
}

const fcServiceabilityCheckMain = async (endpoint: string, request: { config: any; formData: any }) => {
    return endpoints[endpoint](request)
}

export default fcServiceabilityCheckMain
