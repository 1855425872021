// Packages
import React, { useContext } from 'react'
// Contexts
import { FormDataContext } from '../../contexts/FormData'
// Steps
import AcpTransferable from './AcpTransferable'
import NotAcpTransferable from './NotAcpTransferable'

function BasicSubmitOrder(props: any) {
    // Contexts
    const [formData]: any = useContext(FormDataContext)

    if (!formData?.isAcpTransferable) {
        return React.cloneElement(<AcpTransferable />, {
            ...props,
        })
    }

    return React.cloneElement(<NotAcpTransferable />, {
        ...props,
    })
}

export default BasicSubmitOrder
