/* eslint-disable react-hooks/exhaustive-deps */
// Packages
import React, { useEffect, useContext, useState } from 'react'
// Contexts
import { ValidationContext } from '../contexts/Validation'
import { CopyContext } from '../contexts/Copy'
import { ConfigContext } from '../contexts/Config'
// Helpers
import { validateSingleField } from '../helpers/validation/main'
import isLeapYear from '../helpers/dates'
import stringReplace from '../helpers/stringReplace'
import { capitalizeFirstLetter } from '../helpers/string'
// UI
import Dropdown from './Dropdown'

// Interface
// {
//     autoComplete: string
//     children: React.ReactElement | string
//     className: string
//     inputClassName: string
//     disabled: boolean | undefined
//     name: string
//     onChange: Function
//     placeholder: string
//     isNumberType: boolean
// }

function DatePicker({
    step = null,
    children,
    className = '',
    name,
    onChange = () => {},
    validationFunctions = [],
    formDataKey = '',
    disableOnChangeValidation = false,
    initialValue = '//',
}: any) {
    // Contexts
    const [validation, validationDispatch]: any = useContext(ValidationContext)
    const [copy]: any = useContext(CopyContext)
    const [config]: any = useContext(ConfigContext)
    // States
    const [createdValidation, setCreatedValidation] = useState(false)
    const [value, setValue] = useState(initialValue)
    const [waitToValidate, setWaitToValidate] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    useEffect(() => {
        if (!config?.tenantConfig?.featureFlags?.[`disable${capitalizeFirstLetter(name)}Dropdown`]) {
            if ((step || step === 0) && name && !createdValidation) {
                validationDispatch({
                    type: 'ADD_FIELD',
                    payload: {
                        step,
                        name,
                        formDataKey,
                        functions: validationFunctions,
                    },
                })
                setCreatedValidation(true)
            }
        }
    }, [config?.tenantConfig?.featureFlags, createdValidation, formDataKey, name, step, validationFunctions])

    useEffect(() => {
        const asyncWrapper = async () => {
            const { message, metaData } = await validateSingleField(value, validationFunctions)
            setErrorMessage(
                stringReplace(copy?.validationErrors?.[message] ? copy?.validationErrors?.[message] : '', metaData)
            )
        }

        if (
            waitToValidate &&
            !disableOnChangeValidation &&
            !config?.tenantConfig?.featureFlags?.[`disable${capitalizeFirstLetter(name)}Dropdown`]
        ) {
            asyncWrapper()
        }
    }, [
        value,
        validationFunctions,
        waitToValidate,
        disableOnChangeValidation,
        copy?.validationErrors,
        config?.tenantConfig?.featureFlags,
        name,
    ])

    useEffect(() => {
        if (validation?.errors?.errorMessages?.[name]) {
            if (errorMessage !== validation.errors[name]) {
                setErrorMessage(
                    stringReplace(
                        copy?.validationErrors?.[validation.errors.errorMessages[name]]
                            ? copy?.validationErrors?.[validation.errors.errorMessages[name]]
                            : '',
                        validation.errors.metaDatas[name]
                    )
                )
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [validation.errors])

    useEffect(() => {
        onChange(value)
        if (!waitToValidate) {
            setWaitToValidate(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    const months = [
        { name: 'Month', value: '' },
        { name: '01', value: '01' },
        { name: '02', value: '02' },
        { name: '03', value: '03' },
        { name: '04', value: '04' },
        { name: '05', value: '05' },
        { name: '06', value: '06' },
        { name: '07', value: '07' },
        { name: '08', value: '08' },
        { name: '09', value: '09' },
        { name: '10', value: '10' },
        { name: '11', value: '11' },
        { name: '12', value: '12' },
    ]
    const days = [
        { name: 'Day', value: '' },
        {
            name: '01',
            value: '01',
            monthsWithThisDate: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
        },
        {
            name: '02',
            value: '02',
            monthsWithThisDate: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
        },
        {
            name: '03',
            value: '03',
            monthsWithThisDate: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
        },
        {
            name: '04',
            value: '04',
            monthsWithThisDate: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
        },
        {
            name: '05',
            value: '05',
            monthsWithThisDate: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
        },
        {
            name: '06',
            value: '06',
            monthsWithThisDate: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
        },
        {
            name: '07',
            value: '07',
            monthsWithThisDate: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
        },
        {
            name: '08',
            value: '08',
            monthsWithThisDate: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
        },
        {
            name: '09',
            value: '09',
            monthsWithThisDate: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
        },
        {
            name: '10',
            value: '10',
            monthsWithThisDate: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
        },
        {
            name: '11',
            value: '11',
            monthsWithThisDate: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
        },
        {
            name: '12',
            value: '12',
            monthsWithThisDate: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
        },
        {
            name: '13',
            value: '13',
            monthsWithThisDate: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
        },
        {
            name: '14',
            value: '14',
            monthsWithThisDate: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
        },
        {
            name: '15',
            value: '15',
            monthsWithThisDate: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
        },
        {
            name: '16',
            value: '16',
            monthsWithThisDate: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
        },
        {
            name: '17',
            value: '17',
            monthsWithThisDate: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
        },
        {
            name: '18',
            value: '18',
            monthsWithThisDate: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
        },
        {
            name: '19',
            value: '19',
            monthsWithThisDate: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
        },
        {
            name: '20',
            value: '20',
            monthsWithThisDate: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
        },
        {
            name: '21',
            value: '21',
            monthsWithThisDate: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
        },
        {
            name: '22',
            value: '22',
            monthsWithThisDate: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
        },
        {
            name: '23',
            value: '23',
            monthsWithThisDate: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
        },
        {
            name: '24',
            value: '24',
            monthsWithThisDate: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
        },
        {
            name: '25',
            value: '25',
            monthsWithThisDate: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
        },
        {
            name: '26',
            value: '26',
            monthsWithThisDate: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
        },
        {
            name: '27',
            value: '27',
            monthsWithThisDate: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
        },
        {
            name: '28',
            value: '28',
            monthsWithThisDate: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
        },
        {
            name: '29',
            value: '29',
            monthsWithThisDate: ['01', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
        },
        {
            name: '30',
            value: '30',
            monthsWithThisDate: ['01', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
        },
        { name: '31', value: '31', monthsWithThisDate: ['01', '03', '05', '07', '08', '10', '12'] },
    ]
    const years = [
        { name: 'Year', value: '' },
        { name: '2024', value: '2024' },
        { name: '2023', value: '2023' },
        { name: '2022', value: '2022' },
        { name: '2021', value: '2021' },
        { name: '2020', value: '2020' },
        { name: '2019', value: '2019' },
        { name: '2018', value: '2018' },
        { name: '2017', value: '2017' },
        { name: '2016', value: '2016' },
        { name: '2015', value: '2015' },
        { name: '2014', value: '2014' },
        { name: '2013', value: '2013' },
        { name: '2012', value: '2012' },
        { name: '2011', value: '2011' },
        { name: '2010', value: '2010' },
        { name: '2009', value: '2009' },
        { name: '2008', value: '2008' },
        { name: '2007', value: '2007' },
        { name: '2006', value: '2006' },
        { name: '2005', value: '2005' },
        { name: '2004', value: '2004' },
        { name: '2003', value: '2003' },
        { name: '2002', value: '2002' },
        { name: '2001', value: '2001' },
        { name: '2000', value: '2000' },
        { name: '1999', value: '1999' },
        { name: '1998', value: '1998' },
        { name: '1997', value: '1997' },
        { name: '1996', value: '1996' },
        { name: '1995', value: '1995' },
        { name: '1994', value: '1994' },
        { name: '1993', value: '1993' },
        { name: '1992', value: '1992' },
        { name: '1991', value: '1991' },
        { name: '1990', value: '1990' },
        { name: '1989', value: '1989' },
        { name: '1988', value: '1988' },
        { name: '1987', value: '1987' },
        { name: '1986', value: '1986' },
        { name: '1985', value: '1985' },
        { name: '1984', value: '1984' },
        { name: '1983', value: '1983' },
        { name: '1982', value: '1982' },
        { name: '1981', value: '1981' },
        { name: '1980', value: '1980' },
        { name: '1979', value: '1979' },
        { name: '1978', value: '1978' },
        { name: '1977', value: '1977' },
        { name: '1976', value: '1976' },
        { name: '1975', value: '1975' },
        { name: '1974', value: '1974' },
        { name: '1973', value: '1973' },
        { name: '1972', value: '1972' },
        { name: '1971', value: '1971' },
        { name: '1970', value: '1970' },
        { name: '1969', value: '1969' },
        { name: '1968', value: '1968' },
        { name: '1967', value: '1967' },
        { name: '1966', value: '1966' },
        { name: '1965', value: '1965' },
        { name: '1964', value: '1964' },
        { name: '1963', value: '1963' },
        { name: '1962', value: '1962' },
        { name: '1961', value: '1961' },
        { name: '1960', value: '1960' },
        { name: '1959', value: '1959' },
        { name: '1958', value: '1958' },
        { name: '1957', value: '1957' },
        { name: '1956', value: '1956' },
        { name: '1955', value: '1955' },
        { name: '1954', value: '1954' },
        { name: '1953', value: '1953' },
        { name: '1952', value: '1952' },
        { name: '1951', value: '1951' },
        { name: '1950', value: '1950' },
        { name: '1949', value: '1949' },
        { name: '1948', value: '1948' },
        { name: '1947', value: '1947' },
        { name: '1946', value: '1946' },
        { name: '1945', value: '1945' },
        { name: '1944', value: '1944' },
        { name: '1943', value: '1943' },
        { name: '1942', value: '1942' },
        { name: '1941', value: '1941' },
        { name: '1940', value: '1940' },
        { name: '1939', value: '1939' },
        { name: '1938', value: '1938' },
        { name: '1937', value: '1937' },
        { name: '1936', value: '1936' },
        { name: '1935', value: '1935' },
        { name: '1934', value: '1934' },
        { name: '1933', value: '1933' },
        { name: '1932', value: '1932' },
        { name: '1931', value: '1931' },
        { name: '1930', value: '1930' },
        { name: '1929', value: '1929' },
        { name: '1928', value: '1928' },
        { name: '1927', value: '1927' },
        { name: '1926', value: '1926' },
        { name: '1925', value: '1925' },
        { name: '1924', value: '1924' },
        { name: '1923', value: '1923' },
        { name: '1922', value: '1922' },
        { name: '1921', value: '1921' },
        { name: '1920', value: '1920' },
        { name: '1919', value: '1919' },
        { name: '1918', value: '1918' },
        { name: '1917', value: '1917' },
        { name: '1916', value: '1916' },
        { name: '1915', value: '1915' },
        { name: '1914', value: '1914' },
        { name: '1913', value: '1913' },
        { name: '1912', value: '1912' },
        { name: '1911', value: '1911' },
        { name: '1910', value: '1910' },
        { name: '1909', value: '1909' },
        { name: '1908', value: '1908' },
        { name: '1907', value: '1907' },
    ]

    if (config?.tenantConfig?.featureFlags?.[`disable${capitalizeFirstLetter(name)}Dropdown`]) {
        return null
    }

    return (
        // eslint-disable-next-line jsx-a11y/label-has-associated-control
        <label className={`${className}`}>
            {children}
            <Dropdown
                name={`${name} Month`}
                initialValue={initialValue.split('/')[0]}
                onChange={(event: any) => {
                    setValue((prev: string) => {
                        const dateArray = prev.split('/')
                        dateArray[0] = event.target.value
                        return dateArray.join('/')
                    })
                }}
                options={months}
            />
            <Dropdown
                name={`${name} Day`}
                initialValue={initialValue.split('/')[1]}
                onChange={(event: any) => {
                    setValue((prev: string) => {
                        const dateArray = prev.split('/')
                        dateArray[1] = event.target.value
                        return dateArray.join('/')
                    })
                }}
                options={days.filter((day) => {
                    const dateArray = value.split('/')
                    const leapYearSelected = isLeapYear(dateArray[2])
                    if (leapYearSelected && dateArray[0] === '02') {
                        return parseInt(day.value, 10) <= 29
                    }
                    if (!dateArray[0]) {
                        return true
                    }
                    if (day.value === '') {
                        return true
                    }

                    return day.monthsWithThisDate?.includes(dateArray[0])
                })}
            />
            <Dropdown
                name={`${name} Year`}
                initialValue={initialValue.split('/')[2]}
                onChange={(event: any) => {
                    setValue((prev: string) => {
                        const dateArray = prev.split('/')
                        dateArray[2] = event.target.value
                        return dateArray.join('/')
                    })
                }}
                options={years}
            />
            {errorMessage}
        </label>
    )
}

export default DatePicker
