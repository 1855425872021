// Packages
import React, { useContext } from 'react'
// Components
import StyleCopy from '../../components/StyleCopy'
// UI
import Page from '../../ui/Page'
import Main from '../../ui/Main'
import Container from '../../ui/Container'
import HeaderText from '../../ui/HeaderText'
import BodyText from '../../ui/BodyText'
import Button from '../../ui/Button'
import Card from '../../ui/Card'
import Checkbox from '../../ui/Checkbox'
import ExtraSmallText from '../../ui/ExtraSmallText'
// Contexts
import { CopyContext } from '../../contexts/Copy'
import { ConfigContext } from '../../contexts/Config'
import { FormDataContext } from '../../contexts/FormData'
// Helpers
import { parseCopy } from '../../helpers/parseCopy'

function AcpTransferable({ onNext, step }: any) {
    // Contexts
    const [copy]: any = useContext(CopyContext)
    const [config]: any = useContext(ConfigContext)
    const [formData, formDataDispatch]: any = useContext(FormDataContext)

    return (
        <Page>
            <Main>
                <Container>
                    <HeaderText name="basicSubmitOrderAcpHeader">
                        <StyleCopy>
                            {parseCopy(copy?.steps?.basicSubmitOrder?.acpTransferable?.title, {
                                config,
                                formData,
                                copy: copy?.steps?.basicSubmitOrder?.acpTransferable,
                            })}
                        </StyleCopy>
                    </HeaderText>
                    <BodyText name="basicSubmitOrderAcpSubHeader">
                        <StyleCopy>
                            {parseCopy(copy?.steps?.basicSubmitOrder?.acpTransferable?.subHeader, {
                                config,
                                formData,
                                copy: copy?.steps?.basicSubmitOrder?.acpTransferable,
                            })}
                        </StyleCopy>
                    </BodyText>
                    <Card>
                        <BodyText name="basicSubmitOrderAcpInfoCardPlan">
                            <StyleCopy>
                                {parseCopy(copy?.steps?.basicSubmitOrder?.acpTransferable?.infoCard?.planSelected, {
                                    config,
                                    formData,
                                    copy: copy?.steps?.basicSubmitOrder?.acpTransferable,
                                })}
                            </StyleCopy>
                        </BodyText>
                        <BodyText name="basicSubmitOrderAcpInfoCardTitle">
                            <StyleCopy>
                                {parseCopy(copy?.steps?.basicSubmitOrder?.acpTransferable?.infoCard?.title, {
                                    config,
                                    formData,
                                    copy: copy?.steps?.basicSubmitOrder?.acpTransferable,
                                })}
                            </StyleCopy>
                        </BodyText>
                        <BodyText name="basicSubmitOrderAcpInfoCardName">
                            <StyleCopy>
                                {parseCopy(copy?.steps?.basicSubmitOrder?.acpTransferable?.infoCard?.name, {
                                    config,
                                    formData,
                                    copy: copy?.steps?.basicSubmitOrder?.acpTransferable,
                                })}
                            </StyleCopy>
                        </BodyText>
                        <BodyText name="basicSubmitOrderAcpInfoCardPhone">
                            <StyleCopy>
                                {parseCopy(copy?.steps?.basicSubmitOrder?.acpTransferable?.infoCard?.phone, {
                                    config,
                                    formData,
                                    copy: copy?.steps?.basicSubmitOrder?.acpTransferable,
                                })}
                            </StyleCopy>
                        </BodyText>
                        <BodyText name="basicSubmitOrderAcpInfoCardAddress">
                            <StyleCopy>
                                {parseCopy(copy?.steps?.basicSubmitOrder?.acpTransferable?.infoCard?.address, {
                                    config,
                                    formData,
                                    copy: copy?.steps?.basicSubmitOrder?.acpTransferable,
                                })}
                            </StyleCopy>
                        </BodyText>
                    </Card>
                    <BodyText name="basicSubmitOrderAcpAmountDue">
                        <StyleCopy>
                            {parseCopy(copy?.steps?.basicSubmitOrder?.acpTransferable?.amountDue, {
                                config,
                                formData,
                                copy: copy?.steps?.basicSubmitOrder?.acpTransferable,
                            })}
                        </StyleCopy>
                    </BodyText>
                    <Checkbox
                        step={step}
                        name="requestBroadbandLabel"
                        initialValue={formData.isBroadbandLabelRequested}
                        popup={copy?.steps?.basicSubmitOrder?.acpTransferable?.broadBandLabelPopup}
                        onChange={(value: boolean) => {
                            formDataDispatch({
                                type: 'SET_FIELD',
                                payload: { name: 'isBroadbandLabelRequested', value },
                            })
                        }}
                    >
                        <StyleCopy>
                            {parseCopy(copy?.steps?.basicSubmitOrder?.acpTransferable?.requestBroadbandLabel, {
                                config,
                                formData,
                                copy: copy?.steps?.basicSubmitOrder?.acpTransferable,
                            })}
                        </StyleCopy>
                    </Checkbox>
                    <Button
                        name="basicSubmitOrderAcpSubmit"
                        onClick={() => {
                            formDataDispatch({
                                type: 'SET_FIELD',
                                payload: { name: 'isOrderSubmitted', value: true },
                            })
                            onNext()
                        }}
                    >
                        <StyleCopy>
                            {parseCopy(copy?.steps?.basicSubmitOrder?.acpTransferable?.submitButton, {
                                config,
                                formData,
                                copy: copy?.steps?.basicSubmitOrder?.acpTransferable,
                            })}
                        </StyleCopy>
                    </Button>
                    <BodyText name="basicSubmitOrderAcpFooterTitle">
                        <StyleCopy>
                            {parseCopy(copy?.steps?.basicSubmitOrder?.acpTransferable?.footerText?.title, {
                                config,
                                formData,
                                copy: copy?.steps?.basicSubmitOrder?.acpTransferable,
                            })}
                        </StyleCopy>
                    </BodyText>
                    <ExtraSmallText name="basicSubmitOrderAcpFooterOne">
                        <StyleCopy>
                            {parseCopy(copy?.steps?.basicSubmitOrder?.acpTransferable?.footerText?.disclaimerOne, {
                                config,
                                formData,
                                copy: copy?.steps?.basicSubmitOrder?.acpTransferable,
                            })}
                        </StyleCopy>
                    </ExtraSmallText>
                    <ExtraSmallText name="basicSubmitOrderAcpFooterTwo">
                        <StyleCopy>
                            {parseCopy(copy?.steps?.basicSubmitOrder?.acpTransferable?.footerText?.disclaimerTwo, {
                                config,
                                formData,
                                copy: copy?.steps?.basicSubmitOrder?.acpTransferable,
                            })}
                        </StyleCopy>
                    </ExtraSmallText>
                    <ExtraSmallText name="basicSubmitOrderAcpFooterThree">
                        <StyleCopy>
                            {parseCopy(copy?.steps?.basicSubmitOrder?.acpTransferable?.footerText?.disclaimerThree, {
                                config,
                                formData,
                                copy: copy?.steps?.basicSubmitOrder?.acpTransferable,
                            })}
                        </StyleCopy>
                    </ExtraSmallText>
                </Container>
            </Main>
        </Page>
    )
}

export default AcpTransferable
