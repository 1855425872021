// Helpers
import { getProviderDisplayName, getProviderSubsidiaries } from './parseTenantName'
import { addDashesToPhone } from './parsePhoneNumber'

const availableFunctions: { [key: string]: Function } = {
    getProviderDisplayName,
    getProviderSubsidiaries,
    addDashesToPhone,
}

const extractCode = (copy: string, variables: { [key: string]: any }) => {
    let returnedCopy = copy
    const codeArray = copy ? new Set(copy.match(/\${.*?}/g)) : []

    codeArray?.forEach((code) => {
        if (/.*\(.*\)/.test(code)) {
            const splitCode = code.substring(2, code.length - 1).split('(')
            const functionName = splitCode[0]
            const parameter = splitCode[1].substring(0, splitCode[1].length - 1).split('.')
            let value: any = variables

            parameter?.forEach((key) => {
                if (value?.[key]) {
                    value = value[key]
                } else {
                    value = ''
                }
            })

            if (availableFunctions?.[functionName]) {
                returnedCopy = returnedCopy.replace(code, availableFunctions[functionName](value))
            } else {
                returnedCopy = returnedCopy.replace(code, '')
            }
        } else {
            const keys = code.substring(2, code.length - 1).split('.')
            let value: any = variables

            keys?.forEach((key) => {
                if (value?.[key]) {
                    value = value[key]
                } else {
                    value = ''
                }
            })

            returnedCopy = returnedCopy.replace(code, value)
        }
    })

    return returnedCopy
}

export const parseCopy = (copy: string, variables: { [key: string]: any }) => {
    return extractCode(copy, variables)
}

export default parseCopy
