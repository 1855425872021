/* eslint-disable react/prop-types */
// Packages
import React, { useEffect, useState } from 'react'
import { GrDown, GrUp } from 'react-icons/gr'
// Components
import ConditionalRender from '../components/ConditionalRender'
// UI
import Button from './Button'
import BodyText from './BodyText'

function OfferCard({
    className = '',
    priceText,
    buttonCopy,
    shortDescription,
    sections,
    showMoreCopy,
    showLessCopy,
    isAcp,
    strikedPriceText,
    giftCard,
    onClick,
}: {
    [key: string]: any
}) {
    // States
    const [showMore, setShowMore]: any = useState(false)
    const [showMoreSections, setShowMoreSections]: any = useState({})
    const [showMoreComponent, setShowMoreComponent]: any = useState(
        <BodyText>
            {showMoreCopy} <GrDown />
        </BodyText>
    )

    useEffect(() => {
        sections?.forEach((section: any, i: Number) => {
            setShowMoreSections((prev: any) => ({
                ...prev,
                [section?.title]: i === 0,
            }))
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className={`${className}`}>
            <ConditionalRender condition={isAcp}>
                <p>{strikedPriceText}</p>
                <p>{priceText}</p>
            </ConditionalRender>
            <ConditionalRender condition={!isAcp}>
                <p>{priceText}</p>
            </ConditionalRender>
            <Button
                onClick={() => {
                    onClick()
                }}
            >
                {buttonCopy}
            </Button>
            <BodyText>{shortDescription}</BodyText>
            <ConditionalRender condition={showMore}>
                {sections?.map((section: { title: string; bullets: string[]; giftCard: string }) => {
                    return (
                        <>
                            <Button
                                onClick={() => {
                                    setShowMoreSections((prev: any) => ({
                                        ...prev,
                                        [section?.title]: !prev?.[section?.title],
                                    }))
                                }}
                            >
                                {section?.title}
                                <ConditionalRender condition={showMoreSections[section?.title]}>
                                    <GrUp />
                                </ConditionalRender>
                                <ConditionalRender condition={!showMoreSections[section?.title]}>
                                    <GrDown />
                                </ConditionalRender>
                            </Button>
                            <ConditionalRender condition={showMoreSections?.[section?.title]}>
                                <ul>
                                    {section?.bullets?.map((bullet: string) => {
                                        // eslint-disable-next-line react/jsx-key
                                        return <li>{bullet}</li>
                                    })}
                                </ul>
                            </ConditionalRender>
                        </>
                    )
                })}
                <ConditionalRender condition={giftCard}>{giftCard}</ConditionalRender>
            </ConditionalRender>
            <Button
                onClick={() => {
                    setShowMoreComponent(
                        !showMore ? (
                            <BodyText>
                                {showLessCopy} <GrUp />
                            </BodyText>
                        ) : (
                            <BodyText>
                                {showMoreCopy} <GrDown />
                            </BodyText>
                        )
                    )
                    setShowMore((prev: boolean) => !prev)
                }}
            >
                {showMoreComponent}
            </Button>
        </div>
    )
}

export default OfferCard
