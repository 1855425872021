// Packages
import React from 'react'
// Forms
import AddressCheck from '../../forms/AddressCheck'
import CallCenterStartForm from '../../forms/CallCenterStartForm'
import LoginForm from '../../forms/LoginForm'

const formNameMapObject: { [index: string]: React.ReactElement } = {
    address: <AddressCheck />,
    callCenterStart: <CallCenterStartForm />,
    login: <LoginForm />,
}

const formNameMap = ({ key, config }: { key: string; config: string }) =>
    React.cloneElement(formNameMapObject?.[key] ? formNameMapObject[key] : formNameMapObject.default, { config })

export default formNameMap
