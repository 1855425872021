// Helpers
import initialState from './initialState'

const UserReducer = (
    state: any,
    action: { type: String; payload: any },
    // eslint-disable-next-line no-unused-vars
    dataFunnelContext: { state: { [key: string]: any }; dispatch: Function }
) => {
    switch (action.type) {
        case 'SET':
            dataFunnelContext.dispatch({ type: 'SET_USER', payload: { ...state, ...action.payload } })
            return { ...state, ...action.payload }
        case 'SET_DEFAULT':
            dataFunnelContext.dispatch({ type: 'SET_USER', payload: initialState })
            return initialState
        default:
            return state
    }
}

export default UserReducer
