// Packages
import React, { useEffect, useState } from 'react'
// UI
import Loading from '../../ui/LoadingBlocker'

function InterimFormP2Branching({ onNext }: any) {
    // States
    const [firedOnNext, setFiredOnNext] = useState(false)

    useEffect(() => {
        const id = setTimeout(
            () => {
                if (!firedOnNext) {
                    onNext()
                    setFiredOnNext(true)
                }
            },
            1000 + Math.floor(Math.random() * 1000)
        )

        return () => clearTimeout(id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <Loading />
}

export default InterimFormP2Branching
