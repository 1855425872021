// Packages
// import { useEffect, useContext } from 'react'
// Contexts
// import { DataFunnelContext } from '../contexts/DataFunnel'

declare global {
    // eslint-disable-next-line no-unused-vars
    interface Window {
        hj: any
    }
}

function DbTracking() {
    // Contexts
    // const [dataFunnel]: any = useContext(DataFunnelContext)

    // HotJar
    // useEffect(() => {
    //     if (dataFunnel?.contexts?.config?.aceId) {
    //         window.hj('identify', dataFunnel.contexts.config.aceId, {
    //             userId: dataFunnel.contexts.config.aceId,
    //         })
    //     }
    // }, [dataFunnel])

    return null
}

export default DbTracking
