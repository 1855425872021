/* eslint-disable react-hooks/exhaustive-deps */
/* eslint no-use-before-define: 0 */
// Packages
import React, { useContext, useEffect, useState } from 'react'
// Components
import ConditionalRender from './ConditionalRender'
import StyleCopy from './StyleCopy'
// UI
import Popup from '../ui/Popup'
import HeaderText from '../ui/HeaderText'
import Button from '../ui/Button'
// Contexts
import { PopupContext } from '../contexts/Popup'
import { CopyContext } from '../contexts/Copy'
import { FormDataContext } from '../contexts/FormData'
import { ConfigContext } from '../contexts/Config'
import { QueryParamsContext } from '../contexts/QueryParams'
// Helpers
import Container from '../ui/Container'
import getConfigData from '../helpers/config/getConfigData'
// pdfs
// import pdfs from '../helpers/supportData/mockS3Buckets/configBucket/mazama/assets/pdfs/index.json'

function DisplayLoadingBlocker() {
    // Contexts
    const [popup, popupDispatch]: any = useContext(PopupContext)
    const [copy]: any = useContext(CopyContext)
    const [config]: any = useContext(ConfigContext)
    const [formData]: any = useContext(FormDataContext)
    const [queryParams]: any = useContext(QueryParamsContext)

    // State
    const [popupUrl, setPopupUrl]: any = useState('')
    const [providerMeta, setProviderMeta]: any = useState('')

    useEffect(() => {
        const asyncWrapper = async () => {
            let pdfUrlItem
            // eslint-disable-next-line prefer-destructuring
            const componentToRender = popup.componentToRender

            if (copy?.popups?.[componentToRender]?.type === 'notHandledProvider') {
                pdfUrlItem = 'notHandledProvider'
            } else {
                pdfUrlItem = await getConfigData('pdfs', config.tenant, {
                    isMock: config?.isMock,
                    configId: queryParams?.configId ? queryParams.configId : config.deployment,
                    configEnvironment: queryParams?.configEnvironment ? queryParams.configEnvironment : config.account,
                    pdfName: copy?.popups?.[componentToRender]?.type,
                })
            }

            const providerMetaResponse = await getConfigData('providersMetaData.json', config.tenant, {
                isMock: config?.isMock,
                configId: queryParams?.configId ? queryParams.configId : config.deployment,
                configEnvironment: queryParams?.configEnvironment ? queryParams.configEnvironment : config.account,
            })
            setPopupUrl(pdfUrlItem)
            setProviderMeta(providerMetaResponse)
        }
        // console.log('copy?.popups?.[popup.componentToRender]?.type', copy?.popups?.[popup.componentToRender]?.type)
        if (copy?.popups?.[popup.componentToRender]?.type) {
            asyncWrapper()
        }
    }, [copy?.popups?.[popup.componentToRender]?.type])

    return (
        <ConditionalRender condition={popup?.componentToRender}>
            <ConditionalRender condition={typeof popup?.componentToRender !== 'string'}>
                <Popup>{popup?.componentToRender}</Popup>
            </ConditionalRender>
            <ConditionalRender condition={typeof popup?.componentToRender === 'string'}>
                <Popup>
                    <ConditionalRender
                        condition={
                            providerMeta[copy?.popups?.[popup.componentToRender]?.type]?.isIbex || formData?.fromIbex
                        }
                    >
                        <Container className="my-2">
                            This provider was returned from Ibex. Click the button below to see offers on their portal:
                            <br />
                            <a
                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                rel="noreferrer"
                                target="_blank"
                                href={`https://token.bundledealer.com/?leadsourcecode=MAZAMAMEDIALLCPVABL&apikey=prod-Pr6PYJ32vbO0evSaS9jAQ==&addr=${formData?.addressStreet}&apt=${formData?.addressSecondary}&city=${formData?.addressCity}&state=${formData?.addressState}&zip=${formData?.addressZip}&agentid=007&ucid=123456&ordersource=callcenter&hidetoaster=false&enableca=1&hideagid=0&hidesid=0&hideheader=1&hidefooter=0`}
                            >
                                <Button>
                                    <StyleCopy>To Bundle Dealer</StyleCopy>
                                </Button>
                            </a>
                        </Container>
                    </ConditionalRender>

                    <ConditionalRender
                        condition={popupUrl !== 'notHandledProvider'}
                        falseReturn={
                            <Container>
                                <HeaderText>
                                    <StyleCopy>{copy?.popups?.[popup.componentToRender]?.components?.title}</StyleCopy>
                                </HeaderText>
                                <Container>
                                    {copy?.popups?.[popup.componentToRender]?.components?.bodyContent?.map(
                                        (item: any) => {
                                            return <p key={item}>{item}</p>
                                        }
                                    )}
                                </Container>
                                <Button
                                    type="ghost"
                                    onClick={() => {
                                        popupDispatch({ type: 'REMOVE_POPUP' })
                                    }}
                                >
                                    <StyleCopy>
                                        {copy?.popups?.[popup.componentToRender]?.components?.closeButton}
                                    </StyleCopy>
                                </Button>
                            </Container>
                        }
                    >
                        <Container className="min-h-80">
                            <iframe className="w-full h-screen px-4 py-8" title="notHandled" src={popupUrl} />
                        </Container>
                        <Button
                            type="ghost"
                            onClick={() => {
                                popupDispatch({ type: 'REMOVE_POPUP' })
                            }}
                        >
                            <StyleCopy>{copy?.popups?.[popup.componentToRender]?.components?.closeButton}</StyleCopy>
                        </Button>
                    </ConditionalRender>
                </Popup>
            </ConditionalRender>
        </ConditionalRender>
    )
}

export default DisplayLoadingBlocker
