// Packages
import React, { useState, useContext } from 'react'
import { signIn, type SignInInput } from 'aws-amplify/auth'
import { signOut } from 'aws-amplify/auth'
import { useNavigate } from 'react-router-dom'

// Contexts
import { UserContext } from '../../contexts/User'
import { ConfigContext } from '../../contexts/Config'

// Components
import ConditionalRender from '../../components/ConditionalRender'

// UI
// import TextField from '../../ui/TextField'
import Button from '../../ui/Button'
import Container from '../../ui/Container'
import HeaderText from '../../ui/HeaderText'
import BodyText from '../../ui/BodyText'

function LoginPage() {
    const [userEmail, setUserEmail] = useState('')
    const [userPassword, setUserPassword] = useState('')
    const [incorrectCreds, setIncorrectCreds] = useState(false)
    const navigate = useNavigate()

    const [, userDispatch]: any = useContext(UserContext)
    const [config, configDispatch]: any = useContext(ConfigContext)

    async function handleSignIn({ username, password }: SignInInput) {
        try {
            await signOut()
            const { isSignedIn, nextStep } = await signIn({ username, password })
            if (isSignedIn) {
                // Direct user to the next page.
                userDispatch({
                    type: 'SET',
                    payload: {
                        username,
                        password,
                        nextStep,
                    },
                })
                configDispatch({
                    type: 'CLEAR_LOADED',
                })
                if (config.account === 'dev') {
                    navigate('/address/?isMock&devMode')
                } else {
                    navigate('/address')
                }
            }
        } catch (error) {
            setIncorrectCreds(true)
        }
    }

    const handleSubmit = (event: any) => {
        event.preventDefault()
        handleSignIn({ username: userEmail, password: userPassword })
    }

    return (
        <Container className="container mx-auto h-screen w-screen flex bg-gray-200 m-0">
            <Container className="m-auto bg-white p-4 rounded shadow-lg">
                <HeaderText>Login</HeaderText>
                <hr />
                <form>
                    <Container className="flex flex-col min-w-80 py-2">
                        <input
                            className="border border-gray-300 px-4 py-2 rounded-md focus:outline-none focus:ring focus:border-blue-500 m-1"
                            type="text"
                            placeholder="email"
                            onChange={(e) => setUserEmail(e.target.value)}
                        />
                        <input
                            className="border border-gray-300 px-4 py-2 rounded-md focus:outline-none focus:ring focus:border-blue-500 m-1"
                            type="password"
                            placeholder="password"
                            onChange={(e) => setUserPassword(e.target.value)}
                        />
                        <Button
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded m-1"
                            disabled={false}
                            name="submitButton"
                            onClick={(e: any) => handleSubmit(e)}
                            isSubmitTypeButton="Y"
                        >
                            Submit
                        </Button>
                    </Container>
                </form>
                <ConditionalRender condition={incorrectCreds}>
                    <BodyText className="text-red-500">
                        Incorrect credentials, please try again or contact system admin
                    </BodyText>
                </ConditionalRender>
            </Container>
        </Container>
    )
}

export default LoginPage
