/* eslint-disable react/prop-types */
// Packages
import React, { useContext } from 'react'
import { ConfigContext } from '../contexts/Config'
// Helpers
import { capitalizeFirstLetter } from '../helpers/string'

function HeaderText({ className = '', children, name }: { [key: string]: any }) {
    // Contexts
    const [config]: any = useContext(ConfigContext)

    if (config?.tenantConfig?.featureFlags?.[`disable${capitalizeFirstLetter(name)}HeaderText`]) {
        return null
    }

    return <h1 className={`${className}`}>{children}</h1>
}

export default HeaderText
