/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
// Packages
import React, { useState, useEffect, useContext } from 'react'
// Components
import ConditionalRender from '../../components/ConditionalRender'
// UI
import TextField from '../TextField'
import Dropdown from '../Dropdown'
import Button from '../Button'
// Contexts
import { ValidationContext } from '../../contexts/Validation'
import { ConfigContext } from '../../contexts/Config'
import { FormDataContext } from '../../contexts/FormData'
// Helpers
import usStates from '../../helpers/supportData/usStates'
import { querySmartyAutoComplete } from '../../helpers/smarty'
import { capitalizeFirstLetter } from '../../helpers/string'
// import Container from '../Container'

function AddressField({
    step,
    street,
    secondary,
    city,
    state,
    zip,
    name,
    validationFunctions,
}: {
    [key: string]: any
}) {
    // Contexts
    const [, validationDispatch]: any = useContext(ValidationContext)
    const [config]: any = useContext(ConfigContext)
    const [, formDataDispatch]: any = useContext(FormDataContext)

    // States
    const [createdValidation, setCreatedValidation] = useState(false)
    const [streetState, setStreetState] = useState(street.initialValue)
    const [secondaryState, setSecondaryState] = useState(secondary.initialValue)
    const [selectedAddress, setSelectedAddress]: any = useState({})
    const [lineOneSuggestions, setLineOneSuggestions]: any = useState([])
    const [lineTwoSuggestions, setLineTwoSuggestions]: any = useState([])
    const [disableLineOneSuggestions, setDisableLineOneSuggestions]: any = useState(false)
    const [disableLineTwoSuggestions, setDisableLineTwoSuggestions]: any = useState(false)
    const [clickedSecondaryAddress, setClickedSecondaryAddress]: any = useState(false)
    const [secondaryDesignator, setSecondaryDesignator]: any = useState('')

    useEffect(() => {
        if (!config?.tenantConfig?.featureFlags?.[`disable${capitalizeFirstLetter(name)}TextField`]) {
            if ((step || step === 0) && name && !createdValidation) {
                validationDispatch({
                    type: 'ADD_FIELD',
                    payload: {
                        step,
                        name,
                        formDataKeys: {
                            street: street.formDataKey,
                            secondary: secondary.formDataKey,
                            city: city.formDataKey,
                            state: state.formDataKey,
                            zip: zip.formDataKey,
                        },
                        functions: validationFunctions,
                    },
                })
                setCreatedValidation(true)
            }
        }
    }, [
        city.formDataKey,
        city.name,
        config?.tenantConfig?.featureFlags,
        createdValidation,
        name,
        secondary.formDataKey,
        secondary.name,
        state.formDataKey,
        state.name,
        step,
        street.formDataKey,
        street.name,
        validationFunctions,
        zip.formDataKey,
        zip.name,
    ])

    useEffect(() => {
        const asyncWrapper = async () => {
            if (streetState) {
                const suggestions = await querySmartyAutoComplete(streetState)
                setLineOneSuggestions(suggestions.slice(0, 5))
            } else {
                setLineOneSuggestions([])
            }
        }

        asyncWrapper()
    }, [streetState])

    useEffect(() => {
        const asyncWrapper = async () => {
            if (secondaryState?.secondary && secondaryState?.secondary !== -1) {
                const suggestions = await querySmartyAutoComplete({
                    ...selectedAddress,
                    ...secondaryState,
                })
                setLineTwoSuggestions(suggestions.slice(0, 5))
            } else {
                setLineTwoSuggestions([])
            }
        }

        asyncWrapper()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [secondaryState])

    useEffect(() => {
        if (selectedAddress.street) {
            setDisableLineOneSuggestions(true)
        }
    }, [selectedAddress])
    useEffect(() => {
        if (clickedSecondaryAddress) {
            setDisableLineTwoSuggestions(true)
        }
    }, [clickedSecondaryAddress])

    if (config?.tenantConfig?.featureFlags?.[`disable${capitalizeFirstLetter(name)}TextField`]) {
        return null
    }

    return (
        <>
            <TextField
                className="cursor-text text-base font-800 w-full h-fit block md:text-lg"
                step={step}
                autoComplete="off"
                disableOnChangeValidation
                validationFunctions={street.validation}
                formDataKey={street.formDataKey}
                name={street.name}
                subLabel={street.subLabelCopy}
                initialValue={street.initialValue}
                setValueTo={selectedAddress.street}
                onChange={(value: any, fromSetValueTo = false) => {
                    setStreetState(value)
                    street.onChange(value)
                    setDisableLineOneSuggestions(false)
                    setDisableLineTwoSuggestions(false)
                    setClickedSecondaryAddress(false)
                    if (!fromSetValueTo) {
                        setSelectedAddress({
                            city: -1,
                            zip: -1,
                            state: -1,
                        })
                        setSecondaryDesignator('')
                        setSecondaryState({ secondary: -1 })
                        setDisableLineTwoSuggestions(true)
                    }
                    formDataDispatch({
                        type: 'SET_FIELD',
                        payload: { name: 'eligibleProviders', value: null },
                    })
                }}
            >
                {street.labelCopy}
            </TextField>
            <ConditionalRender condition={!disableLineOneSuggestions}>
                {lineOneSuggestions.map((suggestion: any) => {
                    const address = `${suggestion.streetLine}${suggestion?.city ? `, ${suggestion.city}` : ''}${suggestion?.state ? `, ${suggestion.state}` : ''}${suggestion?.zipcode ? `, ${suggestion.zipcode}` : ''}`
                    const id = `${suggestion.streetLine}${suggestion?.secondary ? `, ${suggestion.secondary}` : ''}${suggestion?.city ? `, ${suggestion.city}` : ''}${suggestion?.state ? `, ${suggestion.state}` : ''}${suggestion?.zipcode ? `, ${suggestion.zipcode}` : ''}${suggestion?.entries ? `, ${suggestion.entries}` : ''}`
                    return (
                        <>
                            <Button
                                key={id}
                                name={address}
                                onClick={() => {
                                    setSelectedAddress({
                                        street: suggestion.streetLine,
                                        city: suggestion.city,
                                        state: suggestion.state,
                                        zip: suggestion.zipcode,
                                    })
                                    if (suggestion.secondary) {
                                        setSecondaryDesignator(suggestion.secondary)
                                        setSecondaryState({
                                            secondary: suggestion.secondary,
                                            entries: suggestion.entries,
                                        })
                                    } else {
                                        setDisableLineTwoSuggestions(true)
                                    }
                                }}
                            >
                                {address}
                            </Button>
                            <hr />
                        </>
                    )
                })}
            </ConditionalRender>
            <TextField
                className="cursor-text relative text-base font-800 w-full h-fit block md:text-lg"
                step={step}
                autoComplete="off"
                disableOnChangeValidation
                validationFunctions={secondary.validation}
                formDataKey={secondary.formDataKey}
                name={secondary.name}
                subLabel={secondary.subLabelCopy}
                initialValue={secondary.initialValue}
                onChange={(value: any) => {
                    secondary.onChange(value)
                    setSecondaryState((prev: any) => ({ ...prev, secondary: value }))
                    setClickedSecondaryAddress(false)
                    setDisableLineTwoSuggestions(false)
                }}
                setValueTo={secondaryState?.secondary}
                staticValue={secondaryDesignator}
            >
                {secondary.labelCopy}
            </TextField>
            <ConditionalRender condition={!disableLineTwoSuggestions}>
                {lineTwoSuggestions.map((suggestion: any) => {
                    const id = `${suggestion.streetLine}${suggestion?.secondary ? `, ${suggestion.secondary}` : ''}${suggestion?.city ? `, ${suggestion.city}` : ''}${suggestion?.state ? `, ${suggestion.state}` : ''}${suggestion?.zipcode ? `, ${suggestion.zipcode}` : ''}${suggestion?.entries ? `, ${suggestion.entries}` : ''}`
                    const secondaryName = `${suggestion.streetLine}${suggestion?.secondary ? `, ${suggestion.secondary}` : ''}${suggestion?.city ? `, ${suggestion.city}` : ''}${suggestion?.state ? `, ${suggestion.state}` : ''}${suggestion?.zipcode ? `, ${suggestion.zipcode}` : ''}`
                    return (
                        <Button
                            name={secondaryName}
                            key={id}
                            onClick={() => {
                                setClickedSecondaryAddress(true)
                                setSelectedAddress({
                                    street: suggestion.streetLine,
                                    city: suggestion.city,
                                    state: suggestion.state,
                                    zip: suggestion.zipcode,
                                })
                                if (suggestion.secondary) {
                                    setSecondaryState({ secondary: suggestion.secondary, entries: suggestion.entries })
                                }
                            }}
                        >
                            {suggestion.secondary}
                        </Button>
                    )
                })}
            </ConditionalRender>
            <TextField
                className="cursor-text relative text-base font-800 w-full h-fit block md:text-lg"
                step={step}
                disableOnChangeValidation
                validationFunctions={city.validation}
                formDataKey={city.formDataKey}
                name={city.name}
                subLabel={city.subLabelCopy}
                initialValue={city.initialValue}
                setValueTo={selectedAddress.city}
                onChange={(value: any) => {
                    city.onChange(value)
                }}
            >
                {city.labelCopy}
            </TextField>
            <Dropdown
                className="text-base font-800 h-fit block cursor-pointer md:text-lg w-1/2"
                step={step}
                disableOnChangeValidation
                validationFunctions={state.validation}
                formDataKey={state.formDataKey}
                name={state.name}
                subLabel={state.subLabelCopy}
                initialValue={state.initialValue}
                setValueTo={selectedAddress.state}
                options={usStates}
                onChange={(value: any) => {
                    state.onChange(value)
                }}
            >
                {state.labelCopy}
            </Dropdown>
            <TextField
                className="cursor-text relative text-base font-800 h-fit block md:text-lg w-1/2"
                step={step}
                disableOnChangeValidation
                validationFunctions={zip.validation}
                formDataKey={zip.formDataKey}
                name={zip.name}
                subLabel={zip.subLabelCopy}
                initialValue={zip.initialValue}
                setValueTo={selectedAddress.zip}
                onChange={(value: any) => {
                    zip.onChange(value)
                }}
            >
                {zip.labelCopy}
            </TextField>
        </>
    )
}

export default AddressField
