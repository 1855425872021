// Packages
import React, { useContext } from 'react'
// Components
import StyleCopy from '../../components/StyleCopy'
import ConditionalRender from '../../components/ConditionalRender'
// UI
import Page from '../../ui/Page'
import Main from '../../ui/Main'
import Container from '../../ui/Container'
import HeaderText from '../../ui/HeaderText'
// import BodyText from '../../ui/BodyText'
import AddressField from '../../ui/AddressField'
// Helpers
import makeBackendRequest from '../../helpers/backend'
import {
    requireNonEmptyValue,
    smartyValidation,
    requireResidentialAddress,
    requireValidAddressLine2,
    requireNotPoBox,
    requireValidAddress,
    requireAddressLine2IfAddressHasUnits,
} from '../../helpers/validation/unitFunctions'
import { parseCopy } from '../../helpers/parseCopy'
// Components
// import ConditionalRender from '../../components/ConditionalRender'
// Contexts
import { FormDataContext } from '../../contexts/FormData'
import { CopyContext } from '../../contexts/Copy'
import { ConfigContext } from '../../contexts/Config'
import { LoadingBlockersContext } from '../../contexts/LoadingBlockers'
import { PopupContext } from '../../contexts/Popup'
import Button from '../../ui/Button'
import BodyText from '../../ui/BodyText'

function BasicAddress({ step }: any) {
    // Contexts
    const [formData, formDataDispatch]: any = useContext(FormDataContext)
    const [copy]: any = useContext(CopyContext)
    const [config]: any = useContext(ConfigContext)
    const [, loadingBlockersDispatch]: any = useContext(LoadingBlockersContext)
    const [, popupDispatch]: any = useContext(PopupContext)
    // States
    // const [displayShippingAddress, setDisplayShippingAddress] = useState(false)

    function handleServiceCheck() {
        loadingBlockersDispatch({ type: 'ADD_BLOCKER', payload: 'setUpFormData' })
        makeBackendRequest(
            { stack: 'fcServiceabilityCheck', endpoint: 'main' },
            { config, formData, formDataDispatch, additionalCallStack: 'ibexServiceabilityCheck', makeBackendRequest }
        )
            .then((response) => {
                try {
                    return response.json()
                } catch (error) {
                    return response
                }
            })
            .then((data) => {
                if (data.top_serviceable_company) {
                    formDataDispatch({
                        type: 'SET_FIELD',
                        payload: { name: 'topServiceableCompany', value: data.top_serviceable_company },
                    })
                    formDataDispatch({
                        type: 'SET_FIELD',
                        payload: { name: 'eligibleProvidersString', value: data.serviceability_array.join('|') },
                    })
                    formDataDispatch({
                        type: 'SET_FIELD',
                        payload: { name: 'eligibleProviders', value: data.serviceability_array },
                    })
                    if (data?.ibex_providers) {
                        formDataDispatch({
                            type: 'SET_FIELD',
                            payload: { name: 'fromIbex', value: data?.ibex_providers },
                        })
                    }
                }
                loadingBlockersDispatch({ type: 'REMOVE_BLOCKER', payload: 'setUpFormData' })
            })
    }

    return (
        <Page className="flex mx-auto">
            <Main className="mx-auto bg-white rounded-lg border border-gray-200 shadow-md px-10 py-5 my-10">
                <Container className="flex flex-col mx-auto min-w-32">
                    <HeaderText name="basicAddressSubHeader">
                        <Container className="flex flex-col">
                            <p className="text-3xl md:text-4xl lg:text-5xl font-semibold leading-tight mt-4">
                                {parseCopy(copy?.steps?.basicAddress?.title, { config, formData })}
                            </p>
                            <br />
                            <p className="max-w-96">
                                {parseCopy(copy?.steps?.basicAddress?.subHeader, { config, formData })}
                            </p>
                        </Container>
                    </HeaderText>
                    <AddressField
                        step={step}
                        name="address"
                        validationFunctions={[
                            async (parameters: any) => {
                                return smartyValidation(
                                    { endpoint: '/', request: { config, formData } },
                                    [
                                        requireNotPoBox,
                                        requireValidAddress,
                                        requireResidentialAddress,
                                        requireAddressLine2IfAddressHasUnits,
                                        requireValidAddressLine2,
                                    ],
                                    parameters
                                )
                            },
                        ]}
                        street={{
                            validation: [requireNonEmptyValue],
                            formDataKey: 'addressStreet',
                            name: 'addressStreet',
                            className: 'flex flex-row',
                            subLabelCopy: copy?.steps?.basicAddress?.addressFields?.street?.subLabel && (
                                <StyleCopy>
                                    {parseCopy(copy?.steps?.basicAddress?.addressFields?.street?.subLabel, {
                                        config,
                                        formData,
                                    })}
                                </StyleCopy>
                            ),
                            initialValue: formData.addressStreet,
                            onChange: (value: any) => {
                                formDataDispatch({
                                    type: 'SET_FIELD',
                                    payload: { name: 'addressStreet', value },
                                })
                            },
                            labelCopy: (
                                <StyleCopy>
                                    {parseCopy(copy?.steps?.basicAddress?.addressFields?.street?.label, {
                                        config,
                                        formData,
                                    })}
                                </StyleCopy>
                            ),
                        }}
                        secondary={{
                            validation: [],
                            formDataKey: 'addressSecondary',
                            name: 'addressSecondary',
                            subLabelCopy: copy?.steps?.basicAddress?.addressFields?.line2?.subLabel && (
                                <StyleCopy>
                                    {parseCopy(copy?.steps?.basicAddress?.addressFields?.line2?.subLabel, {
                                        config,
                                        formData,
                                    })}
                                </StyleCopy>
                            ),
                            initialValue: formData.addressSecondary,
                            onChange: (value: any) => {
                                formDataDispatch({
                                    type: 'SET_FIELD',
                                    payload: { name: 'addressSecondary', value },
                                })
                            },
                            labelCopy: (
                                <StyleCopy>
                                    {parseCopy(copy?.steps?.basicAddress?.addressFields?.line2?.label, {
                                        config,
                                        formData,
                                    })}
                                </StyleCopy>
                            ),
                        }}
                        city={{
                            validation: [requireNonEmptyValue],
                            formDataKey: 'addressCity',
                            name: 'addressCity',
                            subLabelCopy: copy?.steps?.basicAddress?.addressFields?.city?.subLabel && (
                                <StyleCopy>
                                    {parseCopy(copy?.steps?.basicAddress?.addressFields?.city?.subLabel, {
                                        config,
                                        formData,
                                    })}
                                </StyleCopy>
                            ),
                            initialValue: formData.addressCity,
                            onChange: (value: any) => {
                                formDataDispatch({
                                    type: 'SET_FIELD',
                                    payload: { name: 'addressCity', value },
                                })
                            },
                            labelCopy: (
                                <StyleCopy>
                                    {parseCopy(copy?.steps?.basicAddress?.addressFields?.city?.label, {
                                        config,
                                        formData,
                                    })}
                                </StyleCopy>
                            ),
                        }}
                        state={{
                            validation: [requireNonEmptyValue],
                            formDataKey: 'addressState',
                            name: 'addressState',
                            subLabelCopy: copy?.steps?.basicAddress?.addressFields?.state?.subLabel && (
                                <StyleCopy>
                                    {parseCopy(copy?.steps?.basicAddress?.addressFields?.state?.subLabel, {
                                        config,
                                        formData,
                                    })}
                                </StyleCopy>
                            ),
                            initialValue: formData.addressState,
                            onChange: (value: any) => {
                                formDataDispatch({
                                    type: 'SET_FIELD',
                                    payload: { name: 'addressState', value },
                                })
                            },
                            labelCopy: (
                                <StyleCopy>
                                    {parseCopy(copy?.steps?.basicAddress?.addressFields?.state?.label, {
                                        config,
                                        formData,
                                    })}
                                </StyleCopy>
                            ),
                        }}
                        zip={{
                            validation: [requireNonEmptyValue],
                            formDataKey: 'addressZip',
                            name: 'addressZip',
                            subLabelCopy: copy?.steps?.basicAddress?.addressFields?.zipCode?.subLabel && (
                                <StyleCopy>
                                    {parseCopy(copy?.steps?.basicAddress?.addressFields?.zipCode?.subLabel, {
                                        config,
                                        formData,
                                    })}
                                </StyleCopy>
                            ),
                            initialValue: formData.addressZip,
                            onChange: (value: any) => {
                                formDataDispatch({
                                    type: 'SET_FIELD',
                                    payload: { name: 'addressZip', value },
                                })
                            },
                            labelCopy: (
                                <StyleCopy>
                                    {parseCopy(copy?.steps?.basicAddress?.addressFields?.zipCode?.label, {
                                        config,
                                        formData,
                                    })}
                                </StyleCopy>
                            ),
                        }}
                    />
                </Container>
                <Button
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded m-1 w-full"
                    onClick={() => {
                        // call button submit handler
                        handleServiceCheck()
                    }}
                >
                    Submit
                </Button>
                <ConditionalRender condition={formData?.eligibleProviders}>
                    <Container className="mt-5">
                        <HeaderText>Click on a provider to view their order process:</HeaderText>
                        {formData?.eligibleProviders?.map((provider: any) => {
                            // Make clickable elements to display a popup of information to the user
                            // TODO: make this pull from parameter store
                            const providerCleaned = provider.toLowerCase().replace(/\s+/g, '')
                            const providersArray = [
                                'optimum',
                                'xfinity',
                                'spectrum',
                                'cox',
                                'frontier',
                                'hughesnet',
                                'viasat',
                                'altafiber',
                                'breezeline',
                                'buckeyebroadband',
                                'maxxsouthbroadband',
                                'clearwavefiber',
                                'mediacom',
                                'metronet',
                                'smithville',
                                'verizon',
                                'windstream',
                                'wow',
                                'ziply',
                            ]
                            let popupValue = providerCleaned
                            if (!providersArray.includes(popupValue)) {
                                popupValue = 'notHandledProvider'
                            }
                            return (
                                <Button
                                    className="mr-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                    key={provider}
                                    onClick={() => {
                                        popupDispatch({
                                            type: 'ADD_POPUP',
                                            payload: {
                                                name: `${popupValue}Popup`,
                                                componentToRender: popupValue,
                                            },
                                        })
                                    }}
                                >
                                    <BodyText>{provider}</BodyText>
                                </Button>
                            )
                        })}
                    </Container>
                </ConditionalRender>
            </Main>
        </Page>
    )
}

export default BasicAddress
