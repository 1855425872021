// Helpers
import initialState from './initialState'

const ConfigReducer = (
    state: any,
    action: { type: String; payload: any },
    // eslint-disable-next-line no-unused-vars
    dataFunnelContext: { state: { [key: string]: any }; dispatch: Function }
) => {
    switch (action.type) {
        case 'SET':
            // dataFunnelContext.dispatch({ type: 'SET_CONFIG', payload: action.payload })
            return action.payload
        case 'SET_DEFAULT':
            // dataFunnelContext.dispatch({ type: 'SET_CONFIG', payload: initialState })
            return initialState
        case 'SET_TENANT':
            // dataFunnelContext.dispatch({ type: 'SET_CONFIG_FIELD', payload: { key: 'tenant', value: action.payload } })
            return { ...state, tenant: action.payload, loaded: { ...state.loaded, tenant: true } }
        case 'SET_TENANT_CONFIG':
            // dataFunnelContext.dispatch({
            //     type: 'SET_CONFIG_FIELD',
            //     payload: { key: 'tenantConfig', value: action.payload },
            // })
            return { ...state, tenantConfig: action.payload, loaded: { ...state.loaded, tenantConfig: true } }
        case 'SET_ACCOUNT':
            // dataFunnelContext.dispatch({ type: 'SET_CONFIG_FIELD', payload: { key: 'account', value: action.payload } })
            return { ...state, account: action.payload, loaded: { ...state.loaded, account: true } }
        case 'SET_DEPLOYMENT':
            // dataFunnelContext.dispatch({
            //     type: 'SET_CONFIG_FIELD',
            //     payload: { key: 'deployment', value: action.payload },
            // })
            return { ...state, deployment: action.payload, loaded: { ...state.loaded, deployment: true } }
        case 'SET_DEV_MODE':
            // dataFunnelContext.dispatch({ type: 'SET_CONFIG_FIELD', payload: { key: 'devMode', value: action.payload } })
            return { ...state, devMode: action.payload, loaded: { ...state.loaded, devMode: true } }
        case 'SET_PATHS':
            // dataFunnelContext.dispatch({ type: 'SET_CONFIG_FIELD', payload: { key: 'paths', value: action.payload } })
            return { ...state, paths: action.payload, loaded: { ...state.loaded, paths: true } }
        case 'SET_SPLIT_PATH_A':
            // dataFunnelContext.dispatch({ type: 'SET_CONFIG_FIELD', payload: { key: 'splitPath', value: 'a' } })
            return { ...state, splitPath: 'a', loaded: { ...state.loaded, splitPath: true } }
        case 'SET_SPLIT_PATH_B':
            // dataFunnelContext.dispatch({ type: 'SET_CONFIG_FIELD', payload: { key: 'splitPath', value: 'b' } })
            return { ...state, splitPath: 'b', loaded: { ...state.loaded, splitPath: true } }
        case 'SET_ENVIRONMENT':
            // dataFunnelContext.dispatch({
            //     type: 'SET_CONFIG_FIELD',
            //     payload: { key: 'environment', value: action.payload },
            // })
            return { ...state, environment: action.payload, loaded: { ...state.loaded, environment: true } }
        case 'SET_CONFIG':
            // dataFunnelContext.dispatch({ type: 'SET_CONFIG_FIELD', payload: { key: 'config', value: action.payload } })
            return {
                ...state,
                config: action.payload,
                loaded: { ...state.loaded, config: true },
            }
        case 'SET_FORM_NAME':
            // dataFunnelContext.dispatch({
            //     type: 'SET_CONFIG_FIELD',
            //     payload: { key: 'formName', value: action.payload },
            // })
            return { ...state, formName: action.payload, loaded: { ...state.loaded, formName: true } }
        case 'SET_ACE_ID':
            // dataFunnelContext.dispatch({
            //     type: 'SET_CONFIG_FIELD',
            //     payload: { key: 'aceId', value: action.payload },
            // })
            return { ...state, aceId: action.payload, loaded: { ...state.loaded, aceId: true } }
        case 'SET_IS_MOCK':
            // dataFunnelContext.dispatch({
            //     type: 'SET_CONFIG_FIELD',
            //     payload: { key: 'isMock', value: action.payload },
            // })
            return { ...state, isMock: action.payload, loaded: { ...state.loaded, isMock: true } }
        case 'MERGE': {
            const newState = { ...state, ...action.payload, loaded: { ...state.loaded, merged: true } }
            // dataFunnelContext.dispatch({ type: 'SET_CONFIG', payload: newState })
            return newState
        }
        case 'CLEAR_LOADED':
            return {
                ...state,
                loaded: {},
            }
        default:
            return state
    }
}

export default ConfigReducer
